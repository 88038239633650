import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const DefaultButton = styled('button')(
  ({ color = QUERIES.secBlack, customCss }) => ({
    border: `1px solid ${color}`,
    borderRadius: '5px',
    color,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    padding: '10px 25px',
    cursor: 'pointer',
    transition: 'all .3s',
    fontSize: 20,

    '&:hover': {
      backgroundColor: `${color}`,
      color: 'white',
    },

    ...customCss,
  }),
)

import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const KyosoTitleWrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',

  '& #element-spacer': {
    display: 'flex',

    '& button:nth-of-type(2)': {
      marginLeft: '20px',
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    flexDirection: 'column',

    '#title': {
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 'bold',
    },

    '& #element-spacer': {
      display: 'flex',
      flexDirection: 'column',
      '& button:nth-of-type(2)': {
        marginLeft: '0px',
        marginTop: '10px',
      },
    },
  },
  ...customCss,
}))

import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const FormInputMessage = styled('p')({
  fontSize: 14,
  margin: '5px 0 0 0',
  position: 'absolute',
  fontWeight: 'normal',
  '&.input-error': {
    color: COLORS.secRed,
  },
  '&.input-success': {
    color: COLORS.secGreen,
  },
})

import React from 'react'
import { FormRadioButtons } from 'shared/components/FormRadioButtons'
import { FormInput } from 'shared/components/FormInput'

const AnswerOverrideCellRenderer = ({ value, api }) => {
  const { exercise_id, exercise_type, overrides } = value

  if (exercise_type === '3' || !overrides.length) {
    return null
  }

  const pointsHandler = (e, answer_id, question_id) => {
    const newOverrides = overrides.map((oldOverride) => {
      if (answer_id) {
        return oldOverride.answer_id === answer_id ? { ...oldOverride, customPoints: e.target.value } : oldOverride
      } else {
        return oldOverride.question_id === question_id ? { ...oldOverride, customPoints: e.target.value } : oldOverride
      }
    })

    // there is only one row
    api.forEachNode(({ data }) => {
      api.applyTransaction({
        update: [{ ...data, [exercise_id]: { ...data[exercise_id], overrides: newOverrides } }]
      })
    })
  }

  const radioHandler = (e, answer_id, question_id) => {
    const evalMapping = e.target.value === '0' ? null : e.target.value === '1'

    const newOverrides = overrides.map((oldOverride) => {
      if (answer_id) {
        return oldOverride.answer_id === answer_id ? { ...oldOverride, customEval: evalMapping } : oldOverride
      } else {
        return oldOverride.question_id === question_id ? { ...oldOverride, customEval: evalMapping } : oldOverride
      }
    })

    // there is only one row
    api.forEachNode(({ data }) => {
      const update = [{ ...data, [exercise_id]: { ...data[exercise_id], overrides: newOverrides } }]

      api.applyTransaction({ update })
    })
  }

  return (
    <div className="answer-override-cell">
      {overrides.map((override) => {
        const { customEval, customPoints, answer_id, answerDescription, question_id } = override

        return (
          <div key={answer_id || question_id}>
            {exercise_type !== '0' && <span>За подточка {answerDescription}</span>}

            <FormRadioButtons
              radioButtons={[
                {
                  id: 0,
                  name: `overrideScore_${answer_id || question_id}`,
                  default: customEval === null,
                  label: 'От системата'
                },
                {
                  id: 1,
                  name: `overrideScore_${answer_id || question_id}`,
                  default: customEval === true,
                  label: 'Верен'
                },
                {
                  id: 2,
                  name: `overrideScore_${answer_id || question_id}`,
                  default: customEval === false,
                  label: 'Грешен'
                }
              ]}
              customCss={{ lineHeight: 1 }}
              type="radio"
              onChange={(e) => radioHandler(e, answer_id, question_id)}
            />
            <FormInput
              value={customPoints || ''}
              label="Точки"
              type="number"
              onChange={(e) => pointsHandler(e, answer_id, question_id)}
              disabled={customEval === null}
            />
          </div>
        )
      })}
    </div>
  )
}

export default AnswerOverrideCellRenderer

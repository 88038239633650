import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const UseGenralDeclarationBtn = styled('button')(() => ({
  alignSelf: 'center',
  maxWidth: 240,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  padding: 10,
  border: `2px solid ${COLORS.secBlack} `,
  backgroundColor: '#ffff',
  borderRadius: 10,
  cursor: 'pointer',
  color: COLORS.secBlack,
  fontSize: 14,

  i: {
    fontSize: 24
  }
}))

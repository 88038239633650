import API from '../../../utils/backendApi'

function getParticipationInfo(childId) {
  return API('hub').GET(`/participation/info?childId=${childId}`)
}

function getDeclarationCheckInfo(childId) {
  return API('hub').GET(`/participation/${childId}/declaration-check`)
}

function participationSignUp(
  parentId,
  childId,
  isDataTouched,
  paymentMethod,
  bankDetails,
  {
    invoiceData = '_omit_',
    newUserData = '_omit_',
    isAutomaticallySigned = false,
  } = {},
) {
  const body = JSON.stringify({
    kyoso_parent_id: parentId,
    kyoso_child_id: childId,
    is_touched_data: isDataTouched,
    is_automatically_signed: isAutomaticallySigned,
    payment_method: paymentMethod,
    new_user_data: newUserData,
    bank_details: bankDetails,
    invoice_data: invoiceData,
  })

  return API('hub').POST('/participation/signup', body)
}

function updateParticipationNewUserData(participationId, newUserDataJSON) {
  const body = JSON.stringify({
    new_user_data: newUserDataJSON,
  })

  return API('hub').PUT(`/participation/${participationId}/new_user_data`, body)
}

function getPersonalResults(childId) {
  return API('hub').GET(`/participation/results/personal/${childId}`)
}

export {
  getDeclarationCheckInfo,
  getParticipationInfo,
  participationSignUp,
  updateParticipationNewUserData,
  getPersonalResults,
}

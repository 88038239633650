import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInputMessage } from 'shared/index'
import HTMLReactParser from 'html-react-parser'

import { COLORS } from 'styling/variables'
import { useController } from 'react-hook-form'

export const FormCheckBoxControlled = styled(
  ({
    id,
    label,
    type = 'checkbox',
    control,
    name,
    error,
    touched,
    successMessage,
    className,
    rules,
    defaultChecked,
    disabled
  }) => {
    const { field } = useController({ name, control, rules, defaultValue: defaultChecked })
    const { onChange, value } = field

    const [checked, setChecked] = useState(value || false)

    useEffect(() => {
      if (field.value === undefined) {
        setChecked(false)
        onChange(false)
      }
    }, [field.value])

    const { t } = useTranslation(['shared'])

    return (
      <label
        htmlFor={id}
        className={className}
        onClick={() => {
          if (!disabled) {
            setChecked(!checked)
            onChange(!checked)
          }
        }}
      >
        <input
          {...field}
          checked={checked}
          disabled={disabled}
          onChange={(e) => {
            setChecked(e.target.checked)
          }}
          type={type}
          className={error ? 'input-error' : successMessage && touched ? 'input-success' : ''}
        />
        <span className="text"> {HTMLReactParser(t(label))}</span>
        <span className="checkmark"></span>
        {error && (
          <FormInputMessage className="input-error">
            <i className="fas fa-exclamation-triangle"></i> {t(`errors:${error.message}`)}
          </FormInputMessage>
        )}
        {touched && !error && successMessage && (
          <FormInputMessage className="input-success">
            <i className="fas fa-check-circle"></i> {t(successMessage)}
          </FormInputMessage>
        )}
      </label>
    )
  }
)(({ customCss }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '25px auto',
  placeItems: 'center',
  justifyContent: 'left',
  marginBottom: '30px',

  ' input': {
    opacity: 0,
    cursor: 'pointer',
    height: ' 25px',
    width: ' 25px',
    gridColumn: '1',
    gridRow: '1',
    zIndex: '999'
  },

  ' .checkmark': {
    position: 'relative',
    borderRadius: '5px',
    gridColumn: '1',
    gridRow: '1',
    top: ' 0px',
    left: ' 0',
    height: ' 25px',
    width: ' 25px',
    backgroundColor: '#fff',
    border: `2px solid ${COLORS.mainGray} `
  },

  ' .text': {
    marginLeft: '12px',
    gridColumn: '2',
    color: COLORS.mainGray
  },

  '&:hover': {
    '.checkmark': {
      backgroundColor: `${COLORS.bgGray}`
    }
  },

  'input:disabled  ~ .checkmark': {
    backgroundColor: `${COLORS.bgGray}`,
    opacity: '0.5'
  },

  'input:disabled ~ .text': {
    color: `${COLORS.mainGray}`,
    opacity: '0.5'
  },

  'input:checked  ~ .checkmark': {
    backgroundColor: COLORS.secBlue,
    border: `2px solid ${COLORS.secBlue} `
  },

  '& .checkmark:after': {
    content: "''",
    position: 'absolute',
    display: 'none'
  },

  'input:checked ~ .checkmark:after': {
    display: 'block'
  },

  'input:checked ~ .text': {
    color: COLORS.secBlue
  },

  '& .input-error': {
    bottom: -20,
    left: 0
  },

  ' & .checkmark:after': {
    left: '6px',
    top: '1px',
    width: '8px',
    height: '14px',
    border: 'solid white',
    borderWidth: '0 3px 3px 0',
    transform: 'rotate(45deg)'
  },

  ...customCss
}))

import React, { useEffect, useRef, useState } from 'react'
import MathJax from 'react-mathjax3'

import styled from '@emotion/styled'
import { onImgZoomClickHandler } from 'utils/helpers/common'
import { COLORS } from 'styling/variables'

// in case we need mathjax context
// function ThirdPartyContextConsumer({ children }, ctx) {
//   return children ? children(ctx) : null
// }
// ThirdPartyContextConsumer.contextTypes = MathJax.Context.childContextTypes

const usePreviousValue = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const TinyTextContentPreviewInternal = ({ html, className, id, onLoadMathJaxHandler = () => {}, imgZoom = false }) => {
  const [isReady, setIsReady] = useState(false)
  const prevHtml = usePreviousValue(html)
  //Set Timeout is here currenty because of the onLoad function, which does not trigger when the content is accessible.
  const onLoadHandler = () =>
    setTimeout(() => {
      const elementContainer = document.getElementById(id)
      let hasClickEvent = false

      if (elementContainer && !hasClickEvent) {
        elementContainer.addEventListener('click', (e) => {
          onImgZoomClickHandler(e)
          hasClickEvent = true
        })
      }
    }, 100)

  useEffect(() => {
    if (html !== prevHtml) {
      setIsReady(false)
      setTimeout(() => {
        setIsReady(true)
      }, 101)
    }
  }, [isReady, html, prevHtml])

  return (
    <div id={id} className={className}>
      <MathJax.Context
        // script is loaded in the index.html file
        script={null}
        // options need work to match the prebuild mathjax config
        // options={{
        //   messageStyle: 'none',
        //   extensions: [
        //     'tex2jax.js',
        //     'mml2jax.js',
        //     'asciimath2jax.js',
        //     'MathEvents.js',
        //     'MathZoom.js',
        //     'MathMenu.js',
        //     'toMathML.js',
        //     'fast-preview.js',
        //     'AssistiveMML.js',
        //     'a11y/accessibility-menu.js'
        //   ],
        //   element: ['mml/jax.js'],
        //   jax: [
        //     'input/TeX',
        //     'output/HTML-CSS',
        //     'input/MathML',
        //     'input/AsciiMath',
        //     'output/PreviewHTML',
        //     'output/CommonHTML'
        //   ],
        //   tex2jax: {
        //     inlineMath: [
        //       ['$', '$'],
        //       ['\\(', '\\)']
        //     ],
        //     displayMath: [
        //       ['$$', '$$'],
        //       ['\\[', '\\]']
        //     ],
        //     processEscapes: true
        //   },
        //   TeX: {
        //     output: ['CommonHTML', 'PreviewHTML'],
        //     extensions: ['noErrors.js', 'noUndefined.js', 'AMSmath.js', 'AMSsymbols.js']
        //   }
        // }}
        onLoad={() => {
          onLoadMathJaxHandler()
          imgZoom && onLoadHandler()
        }}
      >
        <>{isReady && <MathJax.Html html={html} />}</>
      </MathJax.Context>
    </div>
  )
}

export const TinyTextContentPreview = styled(TinyTextContentPreviewInternal)(({ customCss }) => ({
  fontFamily: 'Montserrat',
  fontSize: '16px',

  img: {
    maxWidth: '100%',
    height: 'auto',

    '&.zoom': {
      cursor: 'zoom-in'
    }
  },

  em: {
    fontStyle: 'italic'
  },

  '& .title-1': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '30px'
  },

  '& .title-2': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '26px'
  },

  '& .title-3': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '22px'
  },

  '& .title-4': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '18px'
  },

  '& .paragraph-1': {
    fontFamily: 'Montserrat',
    fontSize: '20px'
  },

  '& .paragraph-2': {
    fontFamily: 'Montserrat',
    fontSize: '18px'
  },

  '& .paragraph-3': {
    fontFamily: 'Montserrat',
    fontSize: '16px'
  },

  '& .paragraph-4': {
    fontFamily: 'Montserrat',
    fontSize: '14px'
  },

  '.overline': {
    textDecoration: 'overline'
  },

  '.tiny-pdf-file-link': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 120,
    gap: 10,
    padding: 10,
    color: COLORS.mainGray,
    textAlign: 'center',
    border: `2px solid ${COLORS.mainGray}`,
    borderRadius: '15px',
    fontSize: '14px',
    textWrap: 'wrap'
  },

  '.tiny-pdf-file-link::before': {
    content: '"\\f1c1"',
    display: 'inline-block',
    color: COLORS.secBlack,
    fontFamily: 'FontAwesome',
    fontSize: '30px'
  },

  ...customCss
}))

export const CONSTANTS = {
  modals: {
    EDIT_MODAL: 'EDIT_MODAL',
    DECLARATION_MODAL: 'DECLARATION_MODAL',
    INVOICE_MODAL: 'INVOICE_MODAL',
    COMPETITIONS_MODAL: 'COMPETITIONS_MODAL',
  },
  answer_types: {
    SINGLE: '0',
    MULTI: '1',
    OPEN: '2',
    LONG: '3',
  },
  scoring_types: {
    INDIVIDUAL: '0',
    COMPLETE_CORRECTNESS: '1',
    SPECIAL_SCORING: '2',
  },
  visibility_types: {
    QUESTIONS: '0',
    USER_ANSWERS: '1',
    CORRECT_ANSWERS: '2',
    SOLUTIONS: '3',
  },
  declaration_status: {
    ANNULED: 'ANNUL',
    IS_VALID: 'IS_VALID',
    RESET: 'RESET',
  },
}

import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const List = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '20px',
  gridRowGap: '5px',
  maxHeight: '608px',
  overflowY: 'auto',
  paddingRight: '10px',

  '::-webkit-scrollbar-track': {
    borderRadius: '10px',
    backgroundColor: '#fff',
  },
  '::-webkit-scrollbar': {
    width: '10px',
    backgroundColor: '#fff',
  },
  '::-webkit-scrollbar-thumb': {
    width: '10px',
    backgroundColor: COLORS.borderGray,
    borderRadius: '10px',
  },
  ...customCss,
}))

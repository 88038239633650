import styled from '@emotion/styled'

export const AnswersLayout = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '20px',
  width: '100%',

  '& :last-of-type': {
    marginBottom: 0,
  },
  ...customCss,
}))

import {
  hub_selectSharedModal,
  hub_hideSharedModal,
} from 'platforms/kyoso-hub/store/generalSlice'
import {
  lab_selectSharedModal,
  lab_hideSharedModal,
} from 'platforms/kyoso-lab/store/generalSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllGrades, getAllKyosoTypes } from 'services/shared'

function useGetSchoolGradesHook() {
  const [grades, setGrades] = useState(null)

  useEffect(() => {
    getAllGrades()
      .then((res) => setGrades(res.grades))
      .catch((err) => console.log(err))
  }, [])

  if (grades?.length) return grades
}

function useSharedModalHook(platform) {
  const dispatch = useDispatch()
  const HubSharedModal = useSelector(hub_selectSharedModal)
  const LabSharedModal = useSelector(lab_selectSharedModal)

  if (platform === 'hub') {
    return {
      ...HubSharedModal,
      hideSharedModal: () => dispatch(hub_hideSharedModal()),
    }
  } else {
    return {
      ...LabSharedModal,
      hideSharedModal: () => dispatch(lab_hideSharedModal()),
    }
  }
}

function useGetAllKyosoTypesHook() {
  const [types, setTypes] = useState(null)

  useEffect(() => {
    getAllKyosoTypes()
      .then((res) => setTypes(res.types))
      .catch((err) => console.log(err))
  }, [])

  if (types?.length) return types
}

function useExerciseCellRendererHook(props) {
  const {
    value
  } = props
  const cellValue = props.valueFormatted ? props.valueFormatted : value

  return (
    <span className="col-value">
      <span className="value">{cellValue}</span>
      <span className="max-points">{4}</span>
    </span>
  )
}

function useGetWindowSizeHook() {
  const isSSR = typeof window === 'undefined'
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  })

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}

export {
  useGetSchoolGradesHook,
  useGetAllKyosoTypesHook,
  useGetWindowSizeHook,
  useExerciseCellRendererHook,
  useSharedModalHook,
}

import styled from '@emotion/styled/macro'
import { COLORS } from 'styling/variables'

export const IconButton = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 28,
  color: COLORS.mainGray,
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  position: 'relative',

  '& i:before': {
    display: 'inline-block',
    textDecoration: 'none',
  },
  '&:hover': {
    background: `${COLORS.bgGray} 0% 0% no-repeat padding-box`,
  },
})

import { Provider } from 'react-redux'
import { RouterConfig } from 'routing/routerConfig'
import store from 'utils/reduxStore'
import { ErrorBoundary } from 'react-error-boundary'

function fallbackRender({ error }) {
  return (
    <div role="alert">
      <p>Възникна грешка, моля презаредете страницата и опитайте отново:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  )
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={fallbackRender}>
      <Provider store={store}>
        <RouterConfig />
      </Provider>
    </ErrorBoundary>
  )
}

export { App }

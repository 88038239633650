import PDFViewer from 'pdf-viewer-reactjs'
import React from 'react'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'

function PdfViewer({ document }) {
  return (
    <>
    Ако файлът не зарежда по някаква причина, може да го <a href={getFileLinkFromServerStorage(document)} target="_blank" rel="noreferrer">достъпите тук.</a>
    <PDFViewer
      document={{
        url: getFileLinkFromServerStorage(document),
      }}
    />
    </>
  )
}

export { PdfViewer }

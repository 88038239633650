import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared/elements/SubmitButtonLab'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { COLORS, QUERIES } from 'styling/variables'
import { useSharedModalHook } from 'utils/helpers/hooks'

function SharedModal({
  type,
  confirmCallback,
  confirmText,
  show,
  color,
  iconClass,
  title,
  content,
  rejectCallback,
  iconText,
  unauthorized,
  customBackgroundCss = {},
  customModalCss = {},
}) {
  const routeHistory = useHistory()
  const platformName = window.location.href.includes('lab') ? 'lab' : 'hub'
  const sharedModal = useSharedModalHook(platformName)
  const { t } = useTranslation()

  const closeModalHandler = () => {
    if (typeof rejectCallback === 'function') {
      rejectCallback()
    } else {
      sharedModal.hideSharedModal()
    }
  }

  return (
    (show || sharedModal.show) && (
      <SharedModalBackground customBackgroundCss={customBackgroundCss}>
        <SharedModalWrapper>
          <SharedModalLayout
            color={color || sharedModal.color}
            type={type || sharedModal.type}
            customModalCss={customModalCss}
          >
            <CloseModalIcon onClick={closeModalHandler}>
              <i className="fas fa-times"></i>
            </CloseModalIcon>
            <div id="sharedModal__title">
              {title?.toUpperCase() || sharedModal.title.toUpperCase()}
            </div>
            <div id="sharedModal__grid">
              <div id="sharedModal__icon">
                {iconText ? (
                  <span id="sharedModal__text">{iconText}</span>
                ) : (
                  <i className={iconClass || sharedModal.iconClass}></i>
                )}
              </div>
              <div id="sharedModal__info">
                <div id="sharedModal__content">
                  {content || sharedModal.content}
                </div>
                {(type || sharedModal.type) === 'action' ? (
                  <ActionTypeFooter>
                    <SubmitButtonLab
                      className="white"
                      onClick={() => {
                        if (typeof rejectCallback === 'function') {
                          rejectCallback()
                        }
                      }}
                    >
                      {t('shared:Denial')}
                    </SubmitButtonLab>
                    <SubmitButtonLab
                      className="black"
                      onClick={() => {
                        if (typeof confirmCallback === 'function') {
                          confirmCallback()
                        }
                      }}
                    >
                      <i className={iconClass || sharedModal.iconClass}></i>
                      <span>{confirmText || sharedModal.confirmText}</span>
                    </SubmitButtonLab>
                  </ActionTypeFooter>
                ) : (
                  <InfoTypeFooter>
                    <div id="text">{t('shared:SharedModalHelpText')}</div>
                    <div id="help">
                      {(unauthorized === false ||
                        sharedModal.unauthorized === false) && (
                        <SubmitButtonLab
                          className="black"
                          onClick={() => {
                            sharedModal.hideSharedModal()
                            routeHistory.push(`/${platformName}/support`)
                          }}
                        >
                          <i className="far fa-question-circle"></i>
                          <span>{t('shared:Help')}</span>
                        </SubmitButtonLab>
                      )}
                      <div id="contact">
                        <a href="mailto:support@kyoso.tech">
                          support@kyoso.tech
                        </a>
                        <a href="tel:+359888441566">+359 888 44 15 66</a>
                      </div>
                    </div>
                  </InfoTypeFooter>
                )}
              </div>
            </div>
          </SharedModalLayout>
        </SharedModalWrapper>
      </SharedModalBackground>
    )
  )
}

const SharedModalBackground = styled('div')(({ customBackgroundCss }) => ({
  display: 'table',
  position: 'fixed',
  top: 65,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(218,222,230,0.7) 0% 0% no-repeat padding-box',
  zIndex: 1000,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'fixed',
    top: 50,
    height: 'calc(100% - 100px)',
  },

  ...customBackgroundCss,
}))

const SharedModalWrapper = styled('div')(() => ({
  display: 'table-cell',
  verticalAlign: 'middle',
}))

const SharedModalLayout = styled('div')(({ color, type, customModalCss }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  width: '95%',
  maxWidth: 710,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000000D',
  borderRadius: 25,
  padding: '30px 60px',
  fontFamily: 'Balsamiq Sans',

  ...customModalCss,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: '30px 20px',
  },

  '& #sharedModal__title': {
    color: color,
    fontSize: 32,
    marginBottom: 60,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    paddingLeft: 100,
    fontWeight: 'bold',

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      paddingLeft: 0,
      fontSize: 22,
      marginBottom: 20,
    },
  },

  '& #sharedModal__grid': {
    display: 'grid',
    gridTemplateColumns: '20% 73%',
    columnGap: 40,

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      gridTemplateColumns: '100%',
      placeItems: 'center',
    },

    '& #sharedModal__icon': {
      fontSize: 86,
      color: color,
      textAlign: 'center',

      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        fontSize: 82,
        marginBottom: 50,
      },

      '& #sharedModal__text': {
        fontSize: 65,
        fontWeight: 'bold',
      },
    },

    '& #sharedModal__info': {
      '& #sharedModal__content': {
        fontSize: 22,
        color: COLORS.secBlack,
        marginBottom: type === 'action' ? 90 : 30,

        [`@media (max-width: ${QUERIES.mobile}px)`]: {
          textAlign: 'center',
          fontSize: 16,
          marginBottom: type === 'action' ? 50 : 30,
        },
      },
    },
  },
}))

const CloseModalIcon = styled('div')({
  position: 'absolute',
  top: 20,
  right: 30,
  fontSize: 29,
  color: COLORS.mainGray,
  cursor: 'pointer',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    right: 25,
    fontSize: 24,
  },
})

const InfoTypeFooter = styled('div')({
  '& #text': {
    textAlign: 'center',
    color: COLORS.mainGray,
    fontSize: 16,
    marginBottom: 15,

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      fontSize: 14,
    },
  },

  '& #help': {
    display: 'flex',
    justifyContent: 'space-between',

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      flexDirection: 'column',
      gap: 15,
      alignItems: 'center',
    },

    [SubmitButtonLab]: {
      maxWidth: 205,
      marginRight: 25,

      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        width: '100%',
        maxWidth: 'none',
        marginRight: 0,
      },

      '& i': {
        marginRight: 10,
      },
    },

    '& #contact': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      gap: 5,

      '& a': {
        textDecoration: 'none',
        color: COLORS.secBlack,
        fontSize: 16,
        fontWeight: 'bold',
        borderBottom: '1px solid transparent',
        transition: '.3s all',

        [`@media (max-width: ${QUERIES.mobile}px)`]: {
          fontSize: 14,
        },

        '&:hover': {
          borderColor: COLORS.secBlack,
        },
      },
    },
  },
})

const ActionTypeFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    flexDirection: 'column',
    gap: 15,
  },

  [`${SubmitButtonLab}:first-of-type`]: {
    maxWidth: 150,
    marginRight: 30,
    backgroundColor: 'white',
    color: COLORS.secBlack,

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      maxWidth: 'none',
      marginRight: 0,
    },

    '&: hover': {
      backgroundColor: COLORS.secBlack,
      color: 'white',
    },
  },

  [`${SubmitButtonLab}:last-of-type`]: {
    '& i': {
      marginRight: 10,
    },
  },
})

export { SharedModal }

import React from 'react'
import styled from '@emotion/styled'
import { mergeDeepRight } from 'ramda'
import { THEMES } from 'styling/variables'

export const SubmitButtonHub = styled(
  ({ type = 'submit', onClick, className, id, disabled = false }) => {
    return (
      <button
        id={id}
        className={className}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        <i className="fas fa-arrow-right"></i>
      </button>
    )
  },
)(({ customCss = {}, theme }) =>
  mergeDeepRight(
    {
      display: 'grid',
      placeItems: 'center',
      right: 0,
      width: 90,
      height: 90,
      padding: 0,
      backgroundColor: `${THEMES[theme || 0]}`,
      borderRadius: 10,
      border: `2px solid ${THEMES[theme || 0]}`,
      textAlign: 'center',
      color: 'white',
      fontSize: 52,
      transition: '0.3s all',

      '&:disabled': {
        opacity: 0.5,
      },

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'white',
        color: `${THEMES[theme || 0]}`,
      },
    },
    customCss,
  ),
)

import { createSlice } from '@reduxjs/toolkit'
import { COLORS } from 'styling/variables'

// Thunks

// Selectors
export const lab_selectSharedModal = (state) => state.lab.general.sharedModal
export const lab_selectGlobalLoader = (state) => state.lab.general.globalLoader

// Configure Slice
const initialState = {
  sharedModal: {
    platform: 'lab',
    show: false,
    color: COLORS.secRed,
    type: 'info',
    iconClass: 'fas fa-exclamation-triangle',
    title: 'Your Title',
    content: 'Your Content',
    unauthorized: false,
  },
  globalLoader: false,
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    lab_showSharedModal(state, action) {
      const allowedFields = [
        'color',
        'iconClass',
        'title',
        'content',
        'unauthorized',
      ]
      Object.keys(action.payload).forEach((k) => {
        if (state.sharedModal[k] !== undefined && allowedFields.includes(k)) {
          state.sharedModal[k] = action.payload[k]
        }
      })
      state.sharedModal.show = true
    },
    lab_hideSharedModal(state) {
      Object.keys(initialState.sharedModal).forEach((k) => {
        state.sharedModal[k] = initialState.sharedModal[k]
      })
    },
    lab_showGlobalLoading(state) {
      state.globalLoader = true
    },
    lab_hideGlobalLoading(state) {
      state.globalLoader = false
    },
  },
})

// Actions and Reducer
export const {
  lab_showSharedModal,
  lab_hideSharedModal,
  lab_showGlobalLoading,
  lab_hideGlobalLoading,
} = generalSlice.actions
export default generalSlice.reducer

import styled from '@emotion/styled'
import { mergeDeepRight } from 'ramda'
import { QUERIES } from 'styling/variables'

export const LoginRegisterSection = styled('div')(
  ({ color, backgroundImgSrc, customCss = {} }) =>
    mergeDeepRight(
      {
        margin: 45,
        width: 'calc(100% - 90px)',
        maxWidth: 500,
        display: 'flex',
        flexDirection: 'column',
        border: `5px solid ${color}`,
        borderRadius: 20,
        padding: '30px 45px 45px 45px',
        backgroundColor: 'white',
        position: 'relative',

        '&:before': {
          content: '""',
          position: 'absolute',
          display: 'inline-block',
          background: `url(${backgroundImgSrc}) no-repeat`,
          backgroundSize: '100% 100%',
          width: 'calc(100% + 90px)',
          height: 'calc(100% + 90px)',
          top: -35,
          left: -35,
          zIndex: -1,
        },

        [`@media (max-width: ${QUERIES.mobile}px)`]: {
          margin: 0,
          width: '100%',
          padding: 30,

          '&:before': {
            transform: 'scale(0.6)',
          },
        },
      },

      customCss,
    ),
)

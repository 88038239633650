import React from 'react'
import styled from '@emotion/styled'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'

export const DropdownMenu = styled(({ items, className }) => {
  return (
    <div className={className}>
      <Tooltip
        overlay={
          <div className="dropdown-menu-component-items">
            {items.map((item) => {
              if (item.render) {
                return (
                  <div key={item.id} onClick={item.onClick} className="dropdown-menu-component-item">
                    {item.render()}
                  </div>
                )
              } else {
                return (
                  <div key={item.id} onClick={item.onClick} className="dropdown-menu-component-item">
                    {item.label}
                  </div>
                )
              }
            })}
          </div>
        }
        placement="bottom"
        trigger="click"
      >
        <i className="fa-solid fa-ellipsis-vertical" />
      </Tooltip>
    </div>
  )
})({
  '.fa-solid': {
    padding: '15px'
  }
})

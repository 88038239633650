import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { FormInputMessage } from 'shared/index'
import { useController } from 'react-hook-form'

import { COLORS } from 'styling/variables'

export const FormRadioButtonsControlled = styled(
  ({ control, options, id, type, label, rules, name, error, disabled, className, defaultValue, ...rest }) => {
    const { field } = useController({ name, control, rules })
    const { t } = useTranslation(['shared'])

    useEffect(() => {
      if (field.value === undefined) {
        field.onChange(defaultValue)
      }
    }, [defaultValue])
    console.log('FormRadioButtonsControlled', field)

    return (
      <label id={id} className={className}>
        <div id="label-wrapper">
          <span id="label">{t(label)}</span>
        </div>
        {error && (
          <FormInputMessage className="input-error">
            <i className="fas fa-exclamation-triangle"></i> {t(`errors:${error.message}`)}
          </FormInputMessage>
        )}
        {options.map((option) => {
          return (
            <Label key={option.value} htmlFor={option.value}>
              <input
                type={type}
                disabled={disabled}
                {...rest}
                {...field}
                value={option.value}
                checked={field.value === option.value}
              />
              <span className="label">{option.optionLabel}</span>

              <span className="checkmark"></span>
            </Label>
          )
        })}
      </label>
    )
  }
)(({ customCss }) => ({
  textAlign: 'left',
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  marginBottom: 30,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',

  ' #label': {
    position: 'relative',
    width: 'auto  '
  },

  '#label-wrapper': {
    display: 'flex',
    alignItems: 'center'
  },

  '& .input-error': {
    margin: '5px 0',
    position: 'relative'
  },

  '& :focus-within': {
    color: COLORS.secBlue
  },

  ...customCss
}))

export const Label = styled('label')({
  position: 'relative',
  marginTop: '15px',
  display: 'block',
  paddingLeft: '35px',
  marginBottom: '12px',
  cursor: 'pointer',
  fontSize: '16px',
  userSelect: 'none',

  'input ': {
    opacity: '0',
    position: 'absolute',
    cursor: 'pointer',
    top: '-4px',
    left: '0px',
    height: '25px',
    width: '100%',
    zIndex: 3
  },

  '.checkmark': {
    position: 'absolute',
    marginTop: '0',
    top: '-4px',
    left: '0px',
    height: '25px',
    width: '25px',
    border: `2px solid ${COLORS.borderGray} `,
    borderRadius: '50%',

    ' :after': {
      content: '""',
      position: 'absolute',
      display: 'none',
      top: '4px',
      left: '4px',
      width: '13px',
      height: '13px',
      borderRadius: '50%',
      background: COLORS.secBlue
    }
  },

  ':hover input ~ .checkmark ': {
    backgroundColor: COLORS.borderGray
  },
  ':hover input ~ .label ': {
    color: COLORS.secBlue
  },

  'input:checked ~ .checkmark:after ': {
    display: 'block'
  },

  'input:checked ~ .checkmark': {
    backgroundColor: '#fff'
  },

  'input:checked ~ .label': {
    color: COLORS.secBlue
  },

  'input:disabled ~ .label': {
    color: COLORS.mainGray
  },

  'input:disabled ~ .checkmark:after': {
    backgroundColor: COLORS.mainGray
  }
})

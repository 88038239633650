import styled from '@emotion/styled/macro'
import { COLORS } from 'styling/variables'

export const SubmitButtonLab = styled('button')(({ customCss }) => ({
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  borderRadius: '5px',
  transition: '0.3s all',
  height: '45px',
  fontFamily: 'Balsamiq Sans',

  '&.black': {
    backgroundColor: `${COLORS.secBlack}`,
    border: `2px solid ${COLORS.secBlack} `,
    color: 'white',

    '&: hover': {
      cursor: 'pointer',
      backgroundColor: 'white',
      color: `${COLORS.secBlack}`,
    },

    '&:disabled': {
      border: COLORS.mainGray,
      backgroundColor: COLORS.mainGray,
    },
  },

  '&.red': {
    backgroundColor: `white`,
    border: `2px solid ${COLORS.secRed} `,
    color: COLORS.secRed,

    '&: hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.secRed,
      color: `white`,
    },
  },

  '&.clear': {
    fontSize: 'larger',
  },

  '&.white': {
    backgroundColor: `#fff`,
    border: `2px solid ${COLORS.secBlack} `,
    color: `${COLORS.secBlack}`,
    '&: hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.secBlack,
      color: `#fff`,
    },
  },

  ...customCss,
}))

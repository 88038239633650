import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const ListWrapper = styled('div')(({ customCss }) => ({
  padding: '10px',
  paddingRight: '0px',
  border: `2px solid ${COLORS.borderGray}`,
  borderRadius: '5px',
  height: 'calc(100% - 35px)',
  ...customCss,
}))

import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const DeclarationInformation = styled('div')(({ customCss }) => ({
  textAlign: 'center',
  margin: '20px 0',

  color: COLORS.mainGray,

  '.declaration': {
    textAlign: 'justify',
    fontFamily: 'Montserrat',

    '.black': {
      color: COLORS.secBlack,
    },

    '.declaration-title': {
      color: COLORS.secBlack,
      textAlign: 'center',
      marginBottom: 20,

      h1: {
        fontSize: 21,
        margin: 0,
      },

      h2: {
        fontSize: 18,
      },
    },

    span: { color: COLORS.secRed, fontWeight: '600' },

    p: { marginBottom: 20 },

    h1: { fontSize: 18, color: COLORS.secBlack },

    h2: { marginBottom: 20 },
  },
  ...customCss,
}))

import API from 'utils/backendApi'
import MD5 from 'crypto-js/md5'

function changeUsername(username) {
  const body = JSON.stringify({
    username,
  })
  return API('lab').PUT('/user/username', body)
}

function changeBankDetails(
  secret_word,
  kin,
  bank_details,
  invoice_data = null,
) {
  const body = JSON.stringify({
    kin,
    secret_word,
    bank_details,
    invoice_data,
  })
  return API('lab').PUT('/user/bankDetails', body)
}

function changePassword(oldPassword, newPassword) {
  const body = JSON.stringify({
    oldPassword: MD5(oldPassword).toString(),
    newPassword: MD5(newPassword).toString(),
  })
  return API('lab').POST('/user/changePassword', body)
}

export { changeUsername, changeBankDetails, changePassword }

import {
  clearAuthToken as lab_clearAuthToken,
  getAuthToken as lab_getAuthToken,
  logOutUser as lab_logOutUser
} from 'platforms/kyoso-lab/services/authentication'
import {
  clearAuthToken as hub_clearAuthToken,
  getAuthToken as hub_getAuthToken,
  logOutUser as hub_logOutUser
} from 'platforms/kyoso-hub/services/authentication'
import { decodeJWT } from 'utils/helpers/common'

const baseURL = `${process.env.REACT_APP_BACKEND_API_URL}/api`

export function askForToken(platform) {
  switch (platform) {
    case 'lab':
      return lab_getAuthToken() ? `Bearer ${lab_getAuthToken()}` : undefined
    case 'hub':
      return hub_getAuthToken() ? `Bearer ${hub_getAuthToken()}` : undefined
    default:
      return undefined
  }
}

export const initiateFileDownload = (data, contentDisposition = 'attachment; filename="my_results.pdf"') => {
  const contentType = 'application/pdf'
  const filename = contentDisposition.match(/filename="(.+)"/)[1]
  const file = new Blob([data], {
    type: contentType
  })
  // For Internet Explorer and Edge
  if ('msSaveOrOpenBlob' in window.navigator) {
    window.navigator.msSaveOrOpenBlob(file, filename)
  }
  // For Firefox and Chrome
  else {
    // Bind blob on disk to ObjectURL
    const data = URL.createObjectURL(file)
    const a = document.createElement('a')
    a.style = 'display: none'
    a.href = data
    a.download = filename
    document.body.appendChild(a)
    a.click()
    // For Firefox
    setTimeout(function () {
      document.body.removeChild(a)
      // Release resource on disk after triggering the download
      window.URL.revokeObjectURL(data)
    }, 100)
  }
}

async function handleResult(response, platform) {
  const data = await response.json()

  if (response.ok) {
    return data
  } else {
    if (response.status === 401 || response.status === 406) {
      if (platform === 'lab') {
        return lab_logOutUser(decodeJWT(lab_getAuthToken()).id).finally(() => {
          lab_clearAuthToken()
          window.location.assign('/lab?unauthorized=true')
          return Promise.reject()
        })
      } else {
        return hub_logOutUser().finally(() => {
          hub_clearAuthToken()
          window.location.assign('/hub?unauthorized=true')
          return Promise.reject()
        })
      }
    } else return Promise.reject({ status: response.status, ...data })
  }
}

const API = (platform) => {
  const authToken = askForToken(platform)
  const platformBaseUrl = `${baseURL}/${platform}`

  return {
    GET: (endpoint, { headers = {}, otherConfigs = {} } = {}) => {
      return window
        .fetch(`${platformBaseUrl}${endpoint}`, {
          method: 'GET',
          headers: {
            Authorization: authToken,
            ...headers
          },
          ...otherConfigs
        })
        .then((response) => handleResult(response, platform))
    },
    POST: (endpoint, body, { headers = {}, otherConfigs = {} } = {}) => {
      let initialHeaders = {
        Authorization: authToken
      }
      if (!(body instanceof FormData)) {
        initialHeaders['Content-Type'] = 'application/json'
      }
      return window
        .fetch(`${platformBaseUrl}${endpoint}`, {
          method: 'POST',
          body,
          headers: {
            ...initialHeaders,
            ...headers
          },
          ...otherConfigs
        })
        .then((response) => handleResult(response, platform))
    },
    PUT: (endpoint, body, { headers = {}, otherConfigs = {} } = {}) => {
      let initialHeaders = {
        Authorization: authToken
      }
      if (!(body instanceof FormData)) {
        initialHeaders['Content-Type'] = 'application/json'
      }
      return window
        .fetch(`${platformBaseUrl}${endpoint}`, {
          method: 'PUT',
          body,
          headers: {
            ...initialHeaders,
            ...headers
          },
          ...otherConfigs
        })
        .then((response) => handleResult(response, platform))
    },
    DELETE: (endpoint, { headers = {}, otherConfigs = {} } = {}) => {
      return window
        .fetch(`${platformBaseUrl}${endpoint}`, {
          method: 'DELETE',
          headers: {
            Authorization: authToken,
            ...headers
          },
          ...otherConfigs
        })
        .then((response) => handleResult(response, platform))
    },
    DOWNLOAD_FILE: (
      endpoint,
      { method = 'get', body = null, responseType = 'arraybuffer', contentType = 'application/json' } = {}
    ) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open(method.toUpperCase(), `${platformBaseUrl}${endpoint}`)
        xhr.responseType = responseType
        xhr.setRequestHeader('Authorization', authToken)
        xhr.setRequestHeader('Content-Type', contentType)
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve({
              data: xhr.response,
              contentDisposition: xhr.getResponseHeader('Content-Disposition')
            })
          } else {
            reject({ status: xhr.status, message: xhr.statusText })
          }
        }
        xhr.onerror = () => {
          reject({ status: xhr.status, message: xhr.statusText })
        }
        xhr.send(body)
      })
    }
  }
}

export default API

import API from '../../../utils/backendApi'
import MD5 from 'crypto-js/md5'

function updateUser(
  user_id,
  {
    username = '_omit_',
    full_name = '_omit_',
    avatar = '_omit_',
    grade_id = '_omit_',
    school = '_omit_',
    theme = '_omit_',
    city = '_omit_',
    parent_names = '_omit_',
    parent_phone = '_omit_',
    is_onboarded = '_omit_',
  } = {},
) {
  const body = JSON.stringify({
    user_id,
    avatar,
    theme,
    username,
    full_name,
    grade_id,
    school,
    city,
    parent_names,
    parent_phone,
    is_onboarded,
  })

  return API('hub').POST('/user/update', body)
}

function checkIfUsernameExists(username, userId) {
  const body = JSON.stringify({
    userId,
    username,
  })

  return API('hub').POST('/user/username', body)
}

function getUserData(userId) {
  return API('hub').GET(`/user/data?userId=${userId}`)
}

function changePassword(oldPassword, newPassword) {
  const body = JSON.stringify({
    oldPassword: MD5(oldPassword).toString(),
    newPassword: MD5(newPassword).toString(),
  })
  return API('hub').POST('/user/changePassword', body)
}

function getDeclaration(userId, childId = null) {
  return API('hub').GET(`/user/${userId}/declaration?childId=${childId}`)
}

function saveDeclaration(signature, type, { child_id = '_omit_' } = {}) {
  const body = JSON.stringify({
    signature,
    child_id,
    type,
  })

  return API('hub').POST('/user/declaration', body)
}

function deleteDeclaration({ child_id = null, type = null } = {}) {
  const body = JSON.stringify({
    child_id,
    type,
  })

  return API('hub').POST('/user/declaration/delete', body)
}

function invalidateDeclaration(declarationId) {
  return API('hub').PUT(`/user/declaration/${declarationId}/invalidate`)
}

export {
  updateUser,
  checkIfUsernameExists,
  getUserData,
  changePassword,
  saveDeclaration,
  deleteDeclaration,
  getDeclaration,
  invalidateDeclaration,
}

import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const TitleIconWrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  marginBottom: 30,
  color: COLORS.secBlack,

  h1: {
    margin: 0,
  },

  i: {
    fontSize: 40,
  },

  ...customCss,
}))

import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { cellClassHandler, exerciseCellRenderer } from 'utils/helpers/grid'
import Modal from 'shared/components/modal/Modal'
import scryll from 'scryll'
import AnswerOverrideCellRenderer from 'shared/components/grid/AnswerOverrideCellRenderer'
import { getRescoredAnswersForChild, saveRescoredAnswersForChild } from 'platforms/kyoso-lab/services/kyoso/children'
import { useTranslation } from 'react-i18next'

const AnswerOverridesModal = ({
  refreshData,
  isModalOpen,
  setIsModalOpen,
  selectedUser,
  defaultColDef,
  isFirstColumn,
  hasModules,
  columnDefs,
  className
}) => {
  const [overrideRowData, setOverrideRowData] = useState([])
  const [gridApi, setGridApi] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    if (isModalOpen) {
      scryll(
        '.grid-with-exercises .ag-body-horizontal-scroll-viewport',
        '.grid-with-overrides .ag-body-horizontal-scroll-viewport',
        { init: true, direction: 'horizontal' }
      )
    }
  }, [isModalOpen])

  const convertPoints = (points) => {
    if (points === null) {
      return null
    } else {
      if (typeof points === 'string') {
        if (points === '') {
          return null
        } else {
          return Number(points)
        }
      } else {
        return String(points)
      }
    }
  }

  useEffect(() => {
    if (selectedUser.length && selectedUser[0].participant_info.participant_uid) {
      getRescoredAnswersForChild(selectedUser[0].participant_info.participant_uid)
        .then((data) => {
          let exercises = []
          if (hasModules) {
            exercises = selectedUser[0].modules
              .map((module, modIndex) => {
                return module.exercises.map((ex, excIndex) => ({ ...ex, modIndex, excIndex }))
              })
              .flat()
          } else {
            exercises = selectedUser[0].exercises
          }

          const overridesRowData = exercises.reduce((acc, exercise) => {
            const excId = exercise.exercise_id
            const answers = exercise.user_answer
            let overrides = []

            if (exercise.exercise_type === '2' && answers) {
              overrides = answers.map((answer) => {
                const existingOverride = data.answer_overrides.find(
                  (override) => override.question_id === excId && override.answer_id === answer.id
                )

                if (existingOverride) {
                  return {
                    answer_id: answer.id,
                    answer: answer.sub_answer,
                    answerDescription: answer.sub_answer_description,
                    customEval: existingOverride.custom_eval,
                    customPoints: convertPoints(existingOverride.custom_points)
                  }
                } else {
                  return {
                    answer_id: answer.id,
                    answer: answer.sub_answer,
                    answerDescription: answer.sub_answer_description,
                    customEval: null,
                    customPoints: null
                  }
                }
              })
            } else if (exercise.exercise_type !== '3' && answers) {
              const existingOverride = data.answer_overrides.find(
                (override) => override.question_id === excId && override.answer_id === null
              )

              if (existingOverride) {
                overrides = [
                  {
                    question_id: excId,
                    customEval: existingOverride.custom_eval,
                    customPoints: convertPoints(existingOverride.custom_points)
                  }
                ]
              } else {
                overrides = [
                  {
                    question_id: excId,
                    customEval: null,
                    customPoints: null
                  }
                ]
              }
            }

            return {
              ...acc,
              [excId]: {
                exercise_type: exercise.exercise_type,
                exercise_id: excId,
                overrides
              }
            }
          }, {})

          setOverrideRowData([overridesRowData])
        })
        .catch(console.error)
    }
  }, [selectedUser])

  const onSubmit = useCallback(() => {
    const rowData = []
    gridApi.forEachNode(function (node) {
      rowData.push(node.data)
    })

    const overrides = rowData[0]
    const overridesArray = Object.values(overrides)
      .filter((override) => override.overrides.length)
      .map((override) => {
        if (override.exercise_type === '2') {
          return override.overrides.map((subOverride) => ({
            question_id: override.exercise_id,
            answer_id: subOverride.answer_id,
            custom_eval: subOverride.customEval,
            custom_points: convertPoints(subOverride.customPoints)
          }))
        } else if (override.exercise_type !== '3') {
          return {
            question_id: override.exercise_id,
            answer_id: null,
            custom_eval: override.overrides[0].customEval,
            custom_points: convertPoints(override.overrides[0].customPoints)
          }
        } else {
          return null
        }
      })
      .flat()
      .filter((override) => override && override.custom_eval !== null)

    saveRescoredAnswersForChild(selectedUser[0].participant_info.participant_uid, overridesArray)
      .then(() => {
        refreshData()
        setIsModalOpen()
      })
      .catch(console.error)
  }, [gridApi, selectedUser, setIsModalOpen, hasModules])

  return (
    <Modal
      show={isModalOpen}
      onClose={() => setIsModalOpen()}
      onOk={onSubmit}
      okButtonText={t('lab:AnswerOverridesModal:okButtonText')}
      onCancel={() => setIsModalOpen()}
      cancelButtonText={t('lab:AnswerOverridesModal:cancelButtonText')}
      height="90vh"
      width="90vw"
      title={t('lab:AnswerOverridesModal:title', {
        name: selectedUser.length
          ? selectedUser[0].participant_info.name
            ? selectedUser[0].participant_info.name
            : selectedUser[0].participant_info.participant_uid
          : ''
      })}
    >
      <div className={`${className} ag-theme-alpine grid-with-exercises`} style={{ width: '100%', height: '400px' }}>
        <AgGridReact
          defaultColDef={{
            ...defaultColDef,
            headerCheckboxSelection: defaultColDef.headerCheckboxSelection && isFirstColumn,
            rowDrag: defaultColDef.rowDrag && isFirstColumn,
            resizable: true
          }}
          columnDefs={columnDefs}
          rowData={selectedUser}
        >
          {hasModules && selectedUser.length
            ? selectedUser[0].modules.map((mod, modIndex) => {
                return (
                  <AgGridColumn key={mod.module_id} headerName={mod.module_name}>
                    {mod.exercises.map((exc, excIndex) => {
                      return (
                        <AgGridColumn
                          cellClass={cellClassHandler({ excIndex, modIndex, hasModules })}
                          cellRenderer={exerciseCellRenderer({ excIndex, modIndex, hasModules })}
                          key={exc.exercise_id}
                          checkboxSelection={false}
                          autoHeight={true}
                          width={180}
                          headerName={`${exc.exercise_name} ${
                            exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                          } `}
                        ></AgGridColumn>
                      )
                    })}
                  </AgGridColumn>
                )
              })
            : selectedUser[0]?.exercises.map((exc, excIndex) => {
                return (
                  <AgGridColumn
                    wrapText={true}
                    cellClass={cellClassHandler({ excIndex, hasModules })}
                    cellRenderer={exerciseCellRenderer({ excIndex })}
                    key={exc.exercise_id}
                    autoHeight={true}
                    width={160}
                    checkboxSelection={false}
                    headerName={`${exc.exercise_name} ${
                      exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                    } `}
                  ></AgGridColumn>
                )
              })}
        </AgGridReact>
      </div>
      <div
        className="ag-theme-alpine grid-with-overrides"
        style={{ width: '100%', height: '400px', paddingTop: '20px' }}
      >
        <AgGridReact
          rowData={overrideRowData}
          onGridReady={(params) => {
            setGridApi(params.api)
          }}
          defaultColDef={{
            rowDrag: false,
            suppressMovable: true,
            suppressMenu: true
          }}
          // there is always only one row
          getRowNodeId={() => 1}
          frameworkComponents={{ answerOverrideCellRenderer: AnswerOverrideCellRenderer }}
        >
          {hasModules && selectedUser.length
            ? selectedUser[0].modules.map((mod) => {
                return (
                  <AgGridColumn key={mod.module_id} headerName={mod.module_name}>
                    {mod.exercises.map((exc) => {
                      return (
                        <AgGridColumn
                          key={exc.exercise_id}
                          field={exc.exercise_id.toString()}
                          checkboxSelection={false}
                          autoHeight={true}
                          width={180}
                          cellClass="override-cell"
                          cellRenderer="answerOverrideCellRenderer"
                          headerName={`${exc.exercise_name} ${
                            exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                          } `}
                        ></AgGridColumn>
                      )
                    })}
                  </AgGridColumn>
                )
              })
            : selectedUser[0]?.exercises.map((exc) => {
                return (
                  <AgGridColumn
                    key={exc.exercise_id}
                    field={exc.exercise_id.toString()}
                    autoHeight={true}
                    checkboxSelection={false}
                    width={180}
                    cellClass="override-cell"
                    cellRenderer="answerOverrideCellRenderer"
                    headerName={`${exc.exercise_name} ${
                      exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                    } `}
                  ></AgGridColumn>
                )
              })}
        </AgGridReact>
      </div>
    </Modal>
  )
}

export default styled(AnswerOverridesModal)({})

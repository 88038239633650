import styled from '@emotion/styled'
import { mergeDeepRight } from 'ramda'
import { QUERIES } from 'styling/variables'

export const ContentTwoColumsLayout = styled('div')(({ customCss = {} }) =>
  mergeDeepRight(
    {
      borderRadius: 25,
      display: 'grid',
      gridGap: 50,
      gridTemplateColumns: '1fr 1fr',

      [`@media (max-width: ${QUERIES.tablet}px)`]: {
        display: 'flex',
        gridGap: 20,
        flexDirection: 'column',
      },
    },
    customCss,
  ),
)

import styled from '@emotion/styled'
import { COLORS, QUERIES, THEMES } from 'styling/variables'

export const TileElement = styled('button')(({ customCss, theme }) => ({
  width: 180,
  height: 150,
  border: `2px solid ${THEMES[+theme || 3]}`,
  backgroundColor: '#ffff',
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 20,
  color: THEMES[+theme || 3],
  fontSize: 16,
  cursor: 'pointer',

  '&.disabled, &:disabled': {
    opacity: 0.5,
    cursor: 'initial',
  },

  i: {
    fontSize: 55,
    color: COLORS.secBlack,
  },

  h1: { fontSize: 50, margin: 0 },

  '&.main': {
    fontSize: 55,
  },

  '&.soon': {
    position: 'relative',
    '&:before': {
      content: '"скоро"',
      position: 'absolute',
      fontSize: 14,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: -5,
      left: -10,
    },
  },
  '&.new': {
    position: 'relative',
    '&:before': {
      content: '"ново!"',
      position: 'absolute',
      fontSize: 14,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: -5,
      left: -10,
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    height: 80,
    padding: 10,

    i: { fontSize: 35 },
    h1: { fontSize: 35 },
  },

  ...customCss,
}))

import API from '../../../utils/backendApi'
import MD5 from 'crypto-js/md5'

const HUB_AUTH_TOKEN = 'kyoso_hub_auth_token'

function getAuthToken() {
  return window.localStorage.getItem(HUB_AUTH_TOKEN) || null
}

function setAuthToken(token) {
  window.localStorage.setItem(HUB_AUTH_TOKEN, token)
}

function loginUser(email, password) {
  const body = JSON.stringify({
    email,
    password: MD5(password).toString(),
  })

  return API('hub').POST('/auth/login', body)
}

function logOutUser(userId) {
  const body = JSON.stringify({
    userId,
  })

  return API('hub').POST('/auth/logout', body)
}

function clearAuthToken() {
  window.localStorage.removeItem(HUB_AUTH_TOKEN)
}

function registerUser(email, password) {
  const body = JSON.stringify({
    email,
    password: MD5(password).toString(),
  })

  return API('hub').POST('/auth/register', body)
}

function checkIfUserEmailExists(email) {
  const body = JSON.stringify({
    email,
  })

  return API('hub').POST('/auth/email', body)
}

function confirmUserEmail(token) {
  const body = JSON.stringify({
    token,
  })

  return API('hub').POST('/auth/confirm', body)
}

function forgotUserPassword(email) {
  const body = JSON.stringify({
    email,
  })

  return API('hub').POST('/auth/forgotten', body)
}

function resetUserPassword(token, password) {
  const body = JSON.stringify({
    token,
    password: MD5(password).toString(),
  })

  return API('hub').POST('/auth/new-pass', body)
}

export {
  getAuthToken,
  setAuthToken,
  clearAuthToken,
  loginUser,
  logOutUser,
  registerUser,
  checkIfUserEmailExists,
  confirmUserEmail,
  forgotUserPassword,
  resetUserPassword,
}

import { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { getAuthToken as lab_getAuthToken, isAdminRole } from 'platforms/kyoso-lab/services/authentication'
import { getAuthToken as hub_getAuthToken } from 'platforms/kyoso-hub/services/authentication'
import { GlobalLoader } from 'shared'
import { useSelector } from 'react-redux'
import { lab_selectUser } from 'platforms/kyoso-lab/store/userSlice'

// HUB Routes
const hub_LandingPage = lazy(() => import('platforms/kyoso-hub/pages/Landing'))
const hub_LandingLayout = lazy(() => import('platforms/kyoso-hub/components/layouts/Landing'))
const hub_OnboardingPage = lazy(() => import('platforms/kyoso-hub/pages/Onboarding'))
const hub_MainLayout = lazy(() => import('platforms/kyoso-hub/components/layouts/Main'))
const hub_SupportPage = lazy(() => import('platforms/kyoso-hub/pages/Support'))
const hub_DashboardPage = lazy(() => import('platforms/kyoso-hub/pages/Dashboard'))
const hub_SettingsPage = lazy(() => import('platforms/kyoso-hub/pages/Settings'))
const hub_CompetitionsPage = lazy(() => import('platforms/kyoso-hub/pages/Competitions'))
const hub_ClassesPage = lazy(() => import('platforms/kyoso-hub/pages/Classes'))
const hub_CompetitionPage = lazy(() => import('platforms/kyoso-hub/pages/Competition'))
const hub_CompetitionView = lazy(() => import('platforms/kyoso-hub/components/competition/CompetitionView'))
const HubUploadExerciseSolutionPage = lazy(() => import('platforms/kyoso-hub/pages/UploadExerciseSolution'))
const HubPublicRankingPage = lazy(() => import('platforms/kyoso-hub/pages/PublicRankingPage'))
const hub_ParticipationPage = lazy(() => import('platforms/kyoso-hub/pages/Participation'))
const hub_CompetitionsSearchPage = lazy(() => import('platforms/kyoso-hub/pages/CompetitionsSearch'))
const hub_BundlesSearch = lazy(() => import('platforms/kyoso-hub/pages/BundlesSearch'))
const hub_BundlePurchasePage = lazy(() => import('platforms/kyoso-hub/pages/BundlePurchase'))
const hub_ResultsPage = lazy(() => import('platforms/kyoso-hub/pages/Results'))
const hub_PersonalResultsPage = lazy(() => import('platforms/kyoso-hub/pages/PersonalResults'))
const hub_RankingPage = lazy(() => import('platforms/kyoso-hub/pages/Ranking'))

// LAB Routes
const lab_LandingPage = lazy(() => import('platforms/kyoso-lab/pages/Landing'))
const lab_LandingLayout = lazy(() => import('platforms/kyoso-lab/components/layouts/Landing'))
const LabMainLayout = lazy(() => import('platforms/kyoso-lab/components/layouts/Main'))
const lab_DashboardPage = lazy(() => import('platforms/kyoso-lab/pages/Dashboard'))
const lab_SupportPage = lazy(() => import('platforms/kyoso-lab/pages/Support'))
const lab_SettingsPage = lazy(() => import('platforms/kyoso-lab/pages/Settings'))
const lab_AdminPage = lazy(() => import('platforms/kyoso-lab/pages/AdminPage'))
const lab_AdminHubPage = lazy(() => import('platforms/kyoso-lab/pages/AdminHubPage'))
const lab_AdminLabPage = lazy(() => import('platforms/kyoso-lab/pages/AdminLabPage'))
const lab_KyosoPage = lazy(() => import('platforms/kyoso-lab/pages/Kyoso'))
const lab_KyosoChild = lazy(() => import('platforms/kyoso-lab/pages/KyosoChild'))
const lab_KyosoChildPreviewPage = lazy(() => import('platforms/kyoso-lab/pages/KyosoChildPreview'))
const lab_KyosoChildTrackingPage = lazy(() => import('platforms/kyoso-lab/pages/KyosoChildTracking'))
const lab_KyosoChildEvaluationPage = lazy(() => import('platforms/kyoso-lab/pages/KyosoChildEvaluation'))
const lab_KyosoParent = lazy(() => import('platforms/kyoso-lab/pages/KyosoParent'))
const lab_KyosoParentPreviewPage = lazy(() => import('platforms/kyoso-lab/pages/KyosoParentPreview'))
const lab_KyosoInvites = lazy(() => import('platforms/kyoso-lab/pages/KyosoInvites'))
const lab_KyosoBundles = lazy(() => import('platforms/kyoso-lab/pages/KyosoBundles'))
const lab_KyosoBundle = lazy(() => import('platforms/kyoso-lab/pages/KyosoBundle'))
const lab_KyosoExercises = lazy(() => import('platforms/kyoso-lab/pages/KyosoExercises'))
const LabNotFoundPage = lazy(() => import('platforms/kyoso-lab/pages/NotFound'))
const lab_KyosoOrdersPage = lazy(() => import('platforms/kyoso-lab/pages/KyosoOrders'))
const lab_KyosoBundleOrdersPage = lazy(() => import('platforms/kyoso-lab/pages/KyosoBundleOrders'))
const lab_KyosoResultsPage = lazy(() => import('platforms/kyoso-lab/pages/KyosoResults'))
const lab_Competition = lazy(() => import('platforms/kyoso-lab/pages/CompetitionLab'))
const lab_CompetitionView = lazy(() => import('platforms/kyoso-lab/components/competition/CompetitionViewLab'))

function RouterConfig() {
  const reduxUser = useSelector(lab_selectUser)

  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader />}>
        <Switch>
          <Redirect exact="true" from="/" to="/hub" />

          {/* LAB */}
          <Route
            path="/lab"
            exact={true}
            render={() => loadPageWithoutAuth(lab_LandingPage, lab_LandingLayout, 'lab')}
          />
          <Route path="/lab/support" render={() => loadProtectedPage(lab_SupportPage, LabMainLayout, 'lab')} />
          <Route path="/lab/settings" render={() => loadProtectedPage(lab_SettingsPage, LabMainLayout, 'lab')} />
          <Route
            path="/lab/admin"
            exact={true}
            render={() => loadProtectedPage(lab_AdminPage, LabMainLayout, 'lab', true, true, isAdminRole(reduxUser))}
          />
          <Route
            path="/lab/admin/admin-hub"
            exact={true}
            render={() => loadProtectedPage(lab_AdminHubPage, LabMainLayout, 'lab', true, true, isAdminRole(reduxUser))}
          />
          <Route
            path="/lab/admin/admin-lab"
            exact={true}
            render={() => loadProtectedPage(lab_AdminLabPage, LabMainLayout, 'lab', true, true, isAdminRole(reduxUser))}
          />
          <Route
            path="/lab/dashboard"
            exact={true}
            render={() => loadProtectedPage(lab_DashboardPage, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoPage, LabMainLayout, 'lab')}
          />
          <Route
            path={['/lab/dashboard/kyoso/parent/insert', '/lab/dashboard/kyoso/parent/:parentId/update']}
            exact={true}
            render={() => loadProtectedPage(lab_KyosoParent, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/parent/:parentId/preview"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoParentPreviewPage, LabMainLayout, 'lab')}
          />
          <Route
            path={[
              '/lab/dashboard/kyoso/child/insert/:parentId/',
              '/lab/dashboard/kyoso/child/edit/:parentId/:childId'
            ]}
            exact={true}
            render={() => loadProtectedPage(lab_KyosoChild, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/child/:parentId/:childId/preview"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoChildPreviewPage, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/child/:parentId/:childId/tracking"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoChildTrackingPage, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/child/:parentId/:childId/evaluation/:userId?"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoChildEvaluationPage, LabMainLayout, 'lab')}
          />
          <Route
              path="/lab/dashboard/kyoso/:parentId/:childId/add-participants"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoInvites, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/bundles"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoBundles, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/bundles/orders/:bundleId"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoBundleOrdersPage, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/bundles/:action(insert|update)?/:bundleId?"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoBundle, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/:parentId/:childId/exercises/"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoExercises, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/:parentId/:childId/orders"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoOrdersPage, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/child/:parentId/:childId/ranking"
            exact={true}
            render={() => loadProtectedPage(lab_KyosoResultsPage, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/:parentId/:childId/competitions"
            exact={true}
            render={() => loadProtectedPage(lab_Competition, LabMainLayout, 'lab')}
          />
          <Route
            path="/lab/dashboard/kyoso/:parentId/:childId/competitions/:moduleIndex/:exerciseIndex"
            exact={true}
            render={() => loadProtectedPage(lab_CompetitionView, LabMainLayout, 'lab')}
          />
          <Route path="/lab/*" exact={true} render={() => <LabMainLayout children={<LabNotFoundPage />} />} />

          {/* HUB */}
          <Route
            path="/hub/:action(login|register)?"
            exact={true}
            render={() => loadPageWithoutAuth(hub_LandingPage, hub_LandingLayout, 'hub')}
          />
          <Route
            path="/hub/dashboard"
            exact={true}
            render={() => loadProtectedPage(hub_DashboardPage, hub_MainLayout, 'hub', true)}
          />
          <Route
            path="/hub/onboarding/:step"
            exact={true}
            render={() => loadProtectedPage(hub_OnboardingPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/competitions"
            exact={true}
            render={() => loadProtectedPage(hub_CompetitionsPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/classes"
            exact={true}
            render={() => loadProtectedPage(hub_ClassesPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/competitions/search"
            exact={true}
            render={() => loadProtectedPage(hub_CompetitionsSearchPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/bundles/search"
            exact={true}
            render={() => loadProtectedPage(hub_BundlesSearch, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/bundles/purchase/:bundleId"
            exact={true}
            render={() => loadProtectedPage(hub_BundlePurchasePage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/competitions/:childId"
            exact={true}
            render={() => loadProtectedPage(hub_CompetitionPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/competitions/:childId/:moduleIndex/:exerciseIndex"
            exact={true}
            render={() => loadProtectedPage(hub_CompetitionView, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/competitions/participation/:childId"
            exact={true}
            render={() => loadProtectedPage(hub_ParticipationPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/results"
            exact={true}
            render={() => loadProtectedPage(hub_ResultsPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/results/personal/:childId"
            exact={true}
            render={() => loadProtectedPage(hub_PersonalResultsPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/results/ranking/:childId"
            exact={true}
            render={() => loadProtectedPage(hub_RankingPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/support"
            exact={true}
            render={() => loadProtectedPage(hub_SupportPage, hub_MainLayout, 'hub')}
          />
          <Route
            path="/hub/settings"
            exact={true}
            render={() => loadProtectedPage(hub_SettingsPage, hub_MainLayout, 'hub')}
          />
          <Route path="/hub/upload-solution/:token" exact={true} render={() => <HubUploadExerciseSolutionPage />} />
          <Route path="/hub/public-ranking/:childId" exact={true} render={() => <HubPublicRankingPage />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

function loadPageWithoutAuth(Component, Layout, platform) {
  const routeConfigs = getPlatformRoutingConfigs(platform, 'noauth')

  if (routeConfigs.token) {
    return <Redirect to={routeConfigs.redirectUrl} />
  } else {
    return <Layout children={<Component />} />
  }
}

function loadProtectedPage(
  Component,
  Layout,
  platform,
  isAdminLayout = false,
  isAdminPage = false,
  hasAdminAccess = false
) {
  const routeConfigs = getPlatformRoutingConfigs(platform, 'protected')

  if (routeConfigs.token) {
    if (isAdminPage) {
      if (hasAdminAccess) {
        return <Layout isAdminLayout={isAdminLayout} children={<Component />} />
      } else {
        return <Redirect to={routeConfigs.redirectUrl} />
      }
    } else {
      return <Layout isAdminLayout={isAdminLayout} children={<Component />} />
    }
  } else {
    return <Redirect to={routeConfigs.redirectUrl} />
  }
}

function getPlatformRoutingConfigs(platform, routeType) {
  const config = {}

  switch (platform) {
    case 'lab':
      config.token = lab_getAuthToken()
      switch (routeType) {
        case 'protected':
          config.redirectUrl = '/lab'
          break
        case 'noauth':
          config.redirectUrl = '/lab/dashboard'
          break
        default:
          config.redirectUrl = '/lab'
          break
      }
      break
    case 'hub':
      config.token = hub_getAuthToken()
      switch (routeType) {
        case 'protected':
          config.redirectUrl = '/hub/login'
          break
        case 'noauth':
          config.redirectUrl = '/hub/dashboard'
          break
        default:
          config.redirectUrl = '/hub'
          break
      }
      break
    default:
      config.token = null
      config.redirectUrl = ''
      break
  }

  return config
}

export { RouterConfig }

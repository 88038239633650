import { createSlice } from '@reduxjs/toolkit'

// Thunks

// Selectors
export const hub_selectPaymentProcessModalState = (state) => {
  return state.hub.payment.paymentProcessModal
}

// Configure Slice
const initialState = {
  paymentProcessModal: {
    show: false,
    paymentStatus: ''
  }
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    hub_showPaymentProcessModal(state, action) {
      state.paymentProcessModal.show = action.payload.show
      state.paymentProcessModal.paymentStatus = action.payload.paymentStatus
    },
    hub_hidePaymentProcessModal(state) {
      state.paymentProcessModal.show = false
      state.paymentProcessModal.paymentStatus = ''
    }
  }
})

// Actions and Reducer
export const { hub_showPaymentProcessModal, hub_hidePaymentProcessModal } = paymentSlice.actions
export default paymentSlice.reducer

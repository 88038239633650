import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const LandingPageFormGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  placeItems: 'center',
  marginBottom: 20,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',
  },
})

import styled from '@emotion/styled/macro'
import { NavLink } from 'react-router-dom'
import { COLORS } from 'styling/variables'

export const IconLinkButton = styled(NavLink)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 28,
  color: COLORS.mainGray,
  cursor: 'pointer',
  borderBottom: `5px solid transparent`,
  height: '100%',
  width: '100%',
  position: 'relative',

  '& i:before': {
    display: 'inline-block',
    textDecoration: 'none',
  },
  '&:hover': {
    background: `${COLORS.bgGray} 0% 0% no-repeat padding-box`,
    borderColor: COLORS.bgGray,
  },
  '&.active': {
    background: `${COLORS.secBlue}14 0% 0% no-repeat padding-box`,
    color: COLORS.secBlue,
    borderColor: COLORS.secBlue,
  },

  '&.soon': {
    background: 'white',
    color: COLORS.mainGray,
    borderBottom: `5px solid transparent`,
    '&:before': {
      content: '"скоро"',
      position: 'absolute',
      fontSize: 12,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: 15,
      left: `calc(50% + 15px)`,
    },
  },
  '&.new': {
    background: 'white',
    color: COLORS.mainGray,
    borderBottom: `5px solid transparent`,
    '&:before': {
      content: '"ново!"',
      position: 'absolute',
      fontSize: 12,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: 15,
      left: `calc(50% + 15px)`,
    },
  },
})

export const IconLinkExternalButton = styled('a')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 28,
  color: COLORS.mainGray,
  cursor: 'pointer',
  borderBottom: `5px solid transparent`,
  height: '100%',
  width: '100%',
  position: 'relative',

  '& i:before': {
    display: 'inline-block',
    textDecoration: 'none',
  },
  '&:hover': {
    background: `${COLORS.bgGray} 0% 0% no-repeat padding-box`,
    borderColor: COLORS.bgGray,
  },
  '&.active': {
    background: `${COLORS.secBlue}14 0% 0% no-repeat padding-box`,
    color: COLORS.secBlue,
    borderColor: COLORS.secBlue,
  },

  '&.soon': {
    background: 'white',
    color: COLORS.mainGray,
    borderBottom: `5px solid transparent`,
    '&:before': {
      content: '"скоро"',
      position: 'absolute',
      fontSize: 12,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: 15,
      left: `calc(50% + 15px)`,
    },
  },
  '&.new': {
    background: 'white',
    color: COLORS.mainGray,
    borderBottom: `5px solid transparent`,
    '&:before': {
      content: '"ново!"',
      position: 'absolute',
      fontSize: 12,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: 15,
      left: `calc(50% + 15px)`,
    },
  },
})

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { COLORS } from 'styling/variables'

export const FormFile = styled(
  ({
    succesMessage,
    register,
    error,
    label,
    type = 'file',
    buttonLabel,
    id,
    onBlur,
    name,
    onChange = () => {},
    readOnly,
    touched,
    className,
    ...inputProps
  }) => {
    const { t } = useTranslation(['shared'])

    return (
      <label className={className}>
        {t(label)}
        <br />

        <Wrapper>
          <Input id="choosenFile" name={name} ref={register} readOnly={readOnly} />
          <label id="uploadButton" htmlFor={id}>
            {t(buttonLabel)}
          </label>
        </Wrapper>

        <input
          hidden
          id={id}
          ref={register}
          name={name}
          type={type}
          readOnly={readOnly}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e)
            document.getElementById('choosenFile').value = e.target.files.length ? e.target.files[0].name : null
          }}
          {...inputProps}
          className={error ? 'input-error' : succesMessage && touched ? 'input-success' : ''}
        />
      </label>
    )
  }
)(({ customCss }) => ({
  textAlign: 'left',
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  marginBottom: 30,
  display: 'inline-block',
  position: 'relative',
  width: '100%',

  '& #uploadButton': {
    marginLeft: '25px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px',
    maxWidth: '134px',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    transition: '0.3s all',
    height: '45px',
    fontWeight: '300',
    backgroundColor: `${COLORS.secBlack}`,
    border: `2px solid ${COLORS.secBlack} `,
    color: 'white',

    '&: hover': {
      cursor: 'pointer',
      backgroundColor: 'white',
      color: `${COLORS.secBlack}`
    }
  },

  '&:hover': {
    color: COLORS.primBlue
  },
  '&:focus-within': {
    color: COLORS.secBlue
  },

  ...customCss
}))

const Input = styled('input')({
  marginTop: 5,
  border: `2px solid ${COLORS.mainGray}`,
  color: COLORS.secBlue,
  borderRadius: 5,
  height: 45,
  width: '100%',
  boxSizing: 'border-box',
  fontSize: 16,
  paddingLeft: 15,
  transition: '0.3s all',
  '&:hover': {
    borderColor: COLORS.primBlue
  },
  '&:focus': {
    borderColor: COLORS.secBlue
  },
  '&.input-error': {
    borderColor: COLORS.secRed
  },
  '&.input-success': {
    borderColor: COLORS.secGreen
  }
})

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center'
})

import API from 'utils/backendApi'

function getAllGrades() {
  return API('shared').GET('/grades')
}

function getAllSubjects() {
  return API('shared').GET('/subjects')
}

function getAllKyosoTypes() {
  return API('shared').GET('/kyoso-types')
}

function getAllLabUsers() {
  return API('shared').GET('/lab_users')
}

export { getAllGrades, getAllSubjects, getAllKyosoTypes, getAllLabUsers }

import API from 'utils/backendApi'

function getAllChildrenOfParent(parentId) {
  return API('lab').GET(`/kyoso/childrenOfParent?parent=${parentId}`)
}

function getChild(childId) {
  return API('lab').GET(`/kyoso/child/${childId}`)
}

function getChildPreview(childId) {
  return API('lab').GET(`/kyoso/child/${childId}/preview`)
}

function insertChild(
  kyoso_parent_id,
  grade_id,
  {
    duration = '_omit_',
    has_modules = '_omit_',
    modules = '_omit_',
    instructions = '_omit_',
    submit_message = '_omit_',
    paid = '_omit_',
    price = '_omit_',
    rules_text = '_omit_',
    rules_file = '_omit_',
    sign_up_type = '_omit_',
    answers_visibility = '_omit_',
    types_to_download_as_file = '_omit_'
  } = {}
) {
  const body = new FormData()
  body.append(
    'data',
    JSON.stringify({
      id: '_omit_',
      kyoso_parent_id,
      grade_id,
      duration,
      has_modules,
      modules,
      instructions,
      submit_message,
      rules_text,
      sign_up_type,
      paid,
      price,
      answers_visibility,
      types_to_download_as_file
    })
  )
  body.append('rules_file', rules_file)

  return API('lab').POST('/kyoso/child', body)
}

function updateChild(
  id,
  {
    grade_id = '_omit_',
    duration = '_omit_',
    has_modules = '_omit_',
    modules = '_omit_',
    instructions = '_omit_',
    submit_message = '_omit_',
    paid = '_omit_',
    price = '_omit_',
    rules_text = '_omit_',
    rules_file = '_omit_',
    sign_up_type = '_omit_',
    answers_visibility = '_omit_',
    types_to_download_as_file = '_omit_'
  } = {}
) {
  const body = new FormData()
  body.append(
    'data',
    JSON.stringify({
      id,
      grade_id,
      duration,
      has_modules,
      modules,
      instructions,
      submit_message,
      rules_text,
      sign_up_type,
      paid,
      price,
      answers_visibility,
      types_to_download_as_file
    })
  )
  body.append('rules_file', rules_file)

  return API('lab').POST('/kyoso/child', body)
}

function duplicateChild(object) {
  const body = JSON.stringify({
    destination_parent_id: object.destination_parent_id,
    child_id: object.child_id
  })
  return API('lab').POST('/kyoso/child/duplicate', body)
}

function duplicateChildStructure(object) {
  const body = JSON.stringify({
    destination_parent_id: object.destination_parent_id,
    child_id: object.child_id
  })
  return API('lab').POST('/kyoso/child/duplicate/structure', body)
}

function checkChildBeforeDelete(childId) {
  const body = JSON.stringify({
    childId
  })

  return API('lab').POST('/kyoso/child/delete/check', body)
}
function deleteChild(childId) {
  const body = JSON.stringify({
    childId
  })

  return API('lab').DELETE('/kyoso/child/delete/delete', {
    headers: { 'content-type': 'application/json' },
    otherConfigs: { body }
  })
}
function removeChildModule(moduleId) {
  return API('lab').DELETE(`/kyoso/child/module/${moduleId}`)
}

function getChildModules(childId) {
  return API('lab').GET(`/kyoso/child/${childId}/modules`)
}

function downloadChildPDF({ childId, isSolutions, isAnswers, isDescriptions }) {
  const body = JSON.stringify({
    childId,
    isSolutions,
    isAnswers,
    isDescriptions
  })
  return API('lab').DOWNLOAD_FILE('/kyoso/child/download/pdf', { method: 'post', body })
}

function getRescoredAnswersForChild(participantId) {
  return API('lab').GET(`/kyoso/competition/rescore/answer-overrides/${participantId}`)
}

function saveRescoredAnswersForChild(participantId, answer_overrides) {
  return API('lab').PUT(`/kyoso/competition/rescore/single-user`, JSON.stringify({ participantId, answer_overrides }))
}

function resetRescoredAnswersForChild(participantId) {
  return API('lab').PUT(`/kyoso/competition/rescore/reset-user`, JSON.stringify({ participantId }))
}

export {
  getAllChildrenOfParent,
  getChild,
  getChildPreview,
  insertChild,
  updateChild,
  duplicateChild,
  removeChildModule,
  getChildModules,
  duplicateChildStructure,
  downloadChildPDF,
  checkChildBeforeDelete,
  deleteChild,
  getRescoredAnswersForChild,
  saveRescoredAnswersForChild,
  resetRescoredAnswersForChild
}

import styled from '@emotion/styled'
import { COLORS, QUERIES, THEMES } from 'styling/variables'

export const CompetitionWrapper = styled('div')(({ customCss, theme, isUpcoming }) => {
  return {
    position: 'relative',
    width: '100%',
    border: isUpcoming ? `2px solid ${THEMES[theme]}` : `1px solid ${COLORS.borderGray} `,
    display: 'flex',
    padding: 15,
    justifyContent: 'space-between',
    borderRadius: 10,

    '#dates-container': {
      flex: 1,
      marginBottom: 8,
      marginTop: 8
    },

    '.competition-content, .classes-content': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',

      '#competition-title': {
        fontWeight: 600,
        color: `${THEMES[+theme]}`,
        lineHeight: 1.3,
        margin: '8px 0px'
      },

      '#competition-details': {
        flex: 1,
        display: 'flex',
        gap: 10,
        flexDirection: 'row'
      }
    },
    '.kyoso_buttons': {
      alignItems: 'center',
      display: 'flex',
      gap: 10
    },
    [`@media (max-width: ${QUERIES.tablet}px)`]: {
      '.kyoso_buttons': {
        justifyContent: 'center'
      }
    },
    ...customCss
  }
})

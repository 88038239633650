import { useEffect, useState } from 'react'
import CryptoJs from 'crypto-js'
import moment from 'moment'
import { hub_showSharedModal } from 'platforms/kyoso-hub/store/generalSlice'
import i18next from 'i18next'
import { lab_showSharedModal } from 'platforms/kyoso-lab/store/generalSlice'

const WSSendTypeEnum = {
  Timer: 'timer',
  NewExerciseMedia: 'new_exercise_media',
  DeleteExerciseMedia: 'delete_exercise_media',
  RemovedDeviceFromCompetition: 'remove_device_from_competition',
}

function formatDateAndTime(date) {
  return moment(date).local().format('DD.MM.YYYY , HH:mm')
}

function calculateSchoolYears() {
  const date = new Date()
  const month = date.getMonth() + 1
  let startSchoolYear = date.getFullYear() - 1
  let endSchoolYear = date.getFullYear()

  if (month >= 8) {
    startSchoolYear += 1
    endSchoolYear += 1
  }

  return { startSchoolYear, endSchoolYear }
}

function decodeJWT(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(jsonPayload)
  } catch (error) {
    return null
  }
}

function useWebSocket(
  userId,
  handleMessage,
  { channel = null, debug = false } = {},
) {
  const [wsInstance, setWsInstance] = useState(null)
  const [connectTimeout, setConnectTimeout] = useState(null)
  const [wsFlag, setWsFlag] = useState(false)

  useEffect(() => {
    const ws = new WebSocket(
      process.env.REACT_APP_BACKEND_WS_URL +
        `/user_id=${userId}&channel=${channel}`,
    )

    ws.onopen = () => {
      debug && console.log('Connected to WebSocket')

      setWsInstance(ws)
      clearTimeout(connectTimeout)
    }

    ws.onmessage = handleMessage

    ws.onclose = (e) => {
      if (e.code !== 3001) {
        debug &&
          console.log(
            `Socket is closed. Reconnect will be attempted in 1 second.`,
            e.reason,
          )

        setConnectTimeout(
          setTimeout(() => {
            debug && console.log('Trying to reconnect...')
            if (!wsInstance || wsInstance.readyState === WebSocket.CLOSED) {
              setWsFlag(!wsFlag)
            }
          }, 1000),
        )
      }
    }

    ws.onerror = (error) => {
      debug &&
        console.error('Socket encountered error: ', error, 'Closing socket')

      ws.close()
    }

    return () => {
      clearTimeout(connectTimeout)
      ws.close(3001)
      debug && console.log(`WebSocket is closed.`)
    }
  }, [wsFlag])

  return wsInstance
}

function getFileLinkFromServerStorage(filePath) {
  return `${process.env.REACT_APP_BACKEND_API_URL}${filePath}`
}

function encodeObjectToBase64(data) {
  try {
    return encodeURIComponent(
      CryptoJs.enc.Base64.stringify(
        CryptoJs.enc.Utf8.parse(JSON.stringify(data)),
      ),
    )
  } catch (error) {
    console.error('Error when trying to encode object to base64: ', error)
    return null
  }
}

function decodeBase64ToObject(data) {
  try {
    return JSON.parse(
      CryptoJs.enc.Utf8.stringify(
        CryptoJs.enc.Base64.parse(decodeURIComponent(data)),
      ),
    )
  } catch (error) {
    console.error('Error when trying to decode base64: ', error)
    return null
  }
}

function handleRejectedFetch(error, { dispatch }) {
  const platformName = window.location.href.includes('lab') ? 'lab' : 'hub'

  if (platformName === 'hub') {
    if (!error?.statCode) {
      dispatch(
        hub_showSharedModal({
          title: i18next.t('errors:NoConnection'),
          content: i18next.t('errors:NoConnectionContent'),
          iconClass: 'fas fa-wifi',
          unauthorized: true,
        }),
      )
    }
  } else {
    if (!error?.statCode) {
      dispatch(
        lab_showSharedModal({
          title: i18next.t('errors:NoConnection'),
          content: i18next.t('errors:NoConnectionContent'),
          iconClass: 'fas fa-wifi',
          unauthorized: true,
        }),
      )
    }
  }
}

function onImgZoomClickHandler({ target }) {
  if (target.className === 'zoom') {
    const bodyEl = document.getElementById('root')
    const imageClone = target.cloneNode()
    const wrapperEl = document.createElement('div')

    const insertedImgElement = wrapperEl.insertAdjacentElement(
      'afterbegin',
      imageClone,
    )

    const insertedWrapperElement = bodyEl.insertAdjacentElement(
      'beforeend',
      wrapperEl,
    )

    //Setting Wrapper El styles
    insertedWrapperElement.style.position = 'fixed'
    insertedWrapperElement.style.width = '100%'
    insertedWrapperElement.style.height = '100%'
    insertedWrapperElement.style.padding = '20px'
    insertedWrapperElement.style.top = '0'
    insertedWrapperElement.style.left = '0'
    insertedWrapperElement.style.right = '0'
    insertedWrapperElement.style.bottom = '0'
    insertedWrapperElement.style.zIndex = '2000'
    insertedWrapperElement.style.display = 'grid'
    insertedWrapperElement.style.placeItems = 'center'
    insertedWrapperElement.style.background = '#ffff'
    insertedWrapperElement.style.cursor = 'zoom-out'
    insertedWrapperElement.style.transition = 'all .3s ease-in-out'

    //Setting Img El styles
    insertedImgElement.style.width = 'auto'
    insertedImgElement.style.height = 'auto'
    insertedImgElement.style.maxHeight = '100vh'
    insertedImgElement.style.transform = 'scale(1)'
    insertedImgElement.style.transition = 'all .3s ease-in-out'

    insertedWrapperElement.addEventListener('click', () => {
      insertedWrapperElement.style.width = '0px'
      insertedWrapperElement.style.opacity = '0'

      setTimeout(() => {
        insertedWrapperElement.remove()
      }, 300)
    })
  }
}

export {
  WSSendTypeEnum,
  formatDateAndTime,
  decodeJWT,
  useWebSocket,
  getFileLinkFromServerStorage,
  encodeObjectToBase64,
  decodeBase64ToObject,
  calculateSchoolYears,
  handleRejectedFetch,
  onImgZoomClickHandler,
}

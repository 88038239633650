import { ModalBackground } from 'shared/elements/ModalBackground'
import { ModalLayout } from 'shared/components/ModalLayout'
import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { EscButton } from 'shared/components/EscButton'

const Modal = styled(
  ({
    className,
    okButtonText,
    cancelButtonText,
    title,
    subTitle,
    children,
    show = false,
    onClose,
    onCancel,
    onOk,
    width,
    height,
    minWidth,
    maxWidth
  }) => {
    return (
      <>
        {show && (
          <ModalBackground>
            <ModalLayout
              customCss={{
                width: width || 'fit-content',
                minWidth: minWidth || 'unset',
                maxWidth: maxWidth || 'unset',
                height: height || 'fit-content'
              }}
            >
              <div className={className}>
                <div className="modal-header-container">
                  <EscButton onClick={onClose} customCss={{ display: 'flex', flexDirection: 'column' }} />
                  {title && <div className="modal-main-header">{title}</div>}
                  {subTitle && <div className="modal-sub-header">{subTitle}</div>}
                </div>
                <div className="modal-body">{children}</div>
                <div className="modal-footer">
                  {cancelButtonText && (
                    <SubmitButtonLab onClick={onCancel} className="white" customCss={{ width: 160 }}>
                      {cancelButtonText}
                    </SubmitButtonLab>
                  )}
                  {okButtonText && (
                    <SubmitButtonLab onClick={onOk} className="black" customCss={{ width: 160 }}>
                      {okButtonText}
                    </SubmitButtonLab>
                  )}
                </div>
              </div>
            </ModalLayout>
          </ModalBackground>
        )}
      </>
    )
  }
)({
  '.modal-body': {
    display: 'flex',
    height: 'height',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    overflowY: 'auto',
    overflow: 'visible',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  },
  '.modal-body::-webkit-scrollbar': {
    display: 'none'
  },

  '.modal-header-container': { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  '.modal-main-header': { fontSize: 20, color: COLORS.secBlack, margin: 15 },
  '.modal-sub-header': { fontSize: 14, color: COLORS.mainGray, margin: 10 },
  '.modal-footer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 12,
    alignItems: 'center',
    position: 'sticky',
    margin: '0 auto'
  }
})

export default Modal

import styled from '@emotion/styled'

export const Avatar = styled('img')(({ customCss }) => ({
  marginTop: '5px',
  width: '100%',
  height: '95%',

  transform: 'scale(1)',

  ...customCss,
}))

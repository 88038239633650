import styled from '@emotion/styled'
import React, { useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import Modal from 'shared/components/modal/Modal'
import { getModulePoints, exerciseCellRenderer, cellClassHandler, exerciseValueGetter } from 'utils/helpers/grid'
import { COLORS } from 'styling/variables'
import { resetRescoredAnswersForChild } from 'platforms/kyoso-lab/services/kyoso/children'
import { formatDateAndTime } from 'utils/helpers/common'
import { ActionMenuCellRenderer } from 'shared/components/grid/ActionMenuCellRenderer'
import AnswerOverridesModal from 'shared/components/grid/AnswerOverridesModal'
import { useTranslation } from 'react-i18next'

export const AgGridComp = styled(
  ({
    hasModules,
    rowData,
    gridOptions,
    customColDef,
    columnDefs,
    className,
    setGridApiCallback = () => {},
    setGridColumnApiCallback = () => {},
    onRowDataChanged = () => {},
    onSelectionChanged = () => {},
    getRowNodeId = () => {},
    isEditableRankColumn,
    refreshData
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isResetModalOpen, setIsResetModalOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState([])
    const { t } = useTranslation()

    const defaultColDef = {
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: 'agTextColumnFilter',
      ...customColDef
    }

    const defaultColGroupDef = {}

    const columnTypes = {
      editableColumn: { editable: true }
    }

    const isFirstColumn = (params) => {
      const displayedColumns = params.columnApi.getAllDisplayedColumns()
      return displayedColumns[0] === params.column
    }

    const onGridReady = (params) => {
      setGridApiCallback(params.api)
      setGridColumnApiCallback(params.columnApi)
    }

    const getDeclarationStat = () => (params) => {
      if (params.data.participant_info.is_declaration_finalized === null) {
        return '<i class="fas fa-times denied"></i>'
      } else if (!params.data.participant_info.is_declaration_finalized) {
        return '<i class="fas fa-info-circle not-confirmed"></i>'
      } else {
        return '<i class="fas fa-check confirmed"></i>'
      }
    }

    const dateFormatter = (time) => (params) => {
      if (time === 'start') {
        return formatDateAndTime(params.data.participant_info.start_date)
      } else {
        return formatDateAndTime(params.data.participant_info.end_date)
      }
    }

    const resetUserScore = () => {
      resetRescoredAnswersForChild(selectedUser[0].participant_info.participant_uid)
        .then(() => {
          setIsResetModalOpen(false)
          setSelectedUser([])
          refreshData()
        })
        .catch(console.error)
    }

    return (
      <>
        <AnswerOverridesModal
          {...{
            refreshData,
            isModalOpen,
            setIsModalOpen: () => {
              setIsModalOpen(false)
              setSelectedUser([])
            },
            selectedUser,
            defaultColDef,
            isFirstColumn,
            hasModules,
            columnDefs,
            className
          }}
        />
        <Modal
          show={isResetModalOpen}
          title={t('lab:AnswerResetModal:title')}
          okButtonText={t('lab:AnswerResetModal:okButtonText')}
          onOk={resetUserScore}
          cancelButtonText={t('lab:AnswerResetModal:cancelButtonText')}
          onClose={() => {
            setIsResetModalOpen(false)
            setSelectedUser([])
          }}
          onCancel={() => {
            setIsResetModalOpen(false)
            setSelectedUser([])
          }}
        />
        <div className={`${className} ag-theme-alpine`}>
          <AgGridReact
            style={{ width: '100%', height: '100%;' }}
            defaultColDef={{
              ...defaultColDef,
              headerCheckboxSelection: defaultColDef.headerCheckboxSelection && isFirstColumn,
              checkboxSelection: defaultColDef.checkboxSelection && isFirstColumn,
              rowDrag: defaultColDef.rowDrag && isFirstColumn,
              resizable: true
            }}
            columnDefs={columnDefs}
            defaultColGroupDef={defaultColGroupDef}
            columnTypes={columnTypes}
            rowData={rowData}
            onRowDataChanged={onRowDataChanged}
            getRowNodeId={getRowNodeId}
            onSelectionChanged={onSelectionChanged}
            gridOptions={{
              getRowClass: (params) => {
                if (params.node.rowIndex % 2 === 0) {
                  return 'row-odd'
                } else return 'row-even'
              },
              enableCellTextSelection: true,
              ...gridOptions
            }}
            frameworkComponents={{
              actionMenu: ActionMenuCellRenderer
            }}
            suppressRowClickSelection={true}
            rowSelection={'multiple'}
            onGridReady={onGridReady}
          >
            <AgGridColumn
              maxWidth={50}
              pinned="left"
              checkboxSelection={true}
              rowDrag={false}
              cellClass="bordered"
            ></AgGridColumn>
            <AgGridColumn
              field="rank"
              pinned="left"
              headerName="No."
              sort="asc"
              editable={isEditableRankColumn ?? true}
              maxWidth={60}
              cellClass="center-aligned bordered"
              checkboxSelection={false}
              type="numericColumn"
              rowDrag={false}
            ></AgGridColumn>
            <AgGridColumn
              pinned="left"
              headerName=""
              maxWidth={40}
              cellClass="center-aligned bordered cell-overflow-visible"
              cellRenderer="actionMenu"
              cellRendererParams={{
                actionItemsGetter: (rowNode) => [
                  {
                    label: t('lab:Results:EditParticipantScore'),
                    id: 'edit',
                    onClick: () => {
                      setSelectedUser([rowNode.data])
                      setIsModalOpen(true)
                    }
                  },
                  {
                    label: t('lab:Results:ResetParticipantScore'),
                    id: 'reset',
                    onClick: () => {
                      setSelectedUser([rowNode.data])
                      setIsResetModalOpen(true)
                    }
                  }
                ]
              }}
              checkboxSelection={false}
              rowDrag={false}
            ></AgGridColumn>
            <AgGridColumn
              field="participant_info.participant_uid"
              headerName="Участник No."
              checkboxSelection={false}
              rowDrag={false}
              maxWidth={120}
              suppressMenu={false}
              pinned="left"
              cellClass="bordered center-aligned"
            ></AgGridColumn>
            <AgGridColumn
              field="participant_info.email"
              headerName="Email"
              checkboxSelection={false}
              rowDrag={false}
              editable={true}
              cellClass="whitespace-normal bordered"
              autoHeight={true}
              width={300}
              maxWidth={400}
            ></AgGridColumn>
            <AgGridColumn
              field="participant_info.name"
              headerName="Име на участник"
              checkboxSelection={false}
              rowDrag={false}
              editable={true}
              cellClass="whitespace-normal bordered"
              autoHeight={true}
              width={300}
              maxWidth={400}
            ></AgGridColumn>
            <AgGridColumn
              field="participant_info.school"
              headerName="Учебно заведение"
              checkboxSelection={false}
              rowDrag={false}
              cellClass="bordered whitespace-normal"
              width={250}
              autoHeight={true}
              maxWidth={300}
            ></AgGridColumn>
            <AgGridColumn
              field="participant_info.city"
              headerName="Населено място"
              checkboxSelection={false}
              rowDrag={false}
              cellClass="bordered whitespace-normal"
              width={150}
              autoHeight={true}
              maxWidth={200}
            ></AgGridColumn>
            <AgGridColumn
              cellRenderer={getDeclarationStat()}
              valueGetter={getDeclarationStat()}
              headerName="Декл."
              checkboxSelection={false}
              rowDrag={false}
              cellClass="bordered center-aligned"
              maxWidth={80}
            ></AgGridColumn>
            <AgGridColumn
              cellRenderer={dateFormatter('start')}
              valueGetter={dateFormatter('start')}
              headerName="Начало"
              checkboxSelection={false}
              rowDrag={false}
              cellClass="bordered center-aligned"
              maxWidth={200}
            ></AgGridColumn>
            <AgGridColumn
              cellRenderer={dateFormatter('end')}
              valueGetter={dateFormatter('end')}
              headerName="Край"
              checkboxSelection={false}
              rowDrag={false}
              cellClass="bordered center-aligned"
              maxWidth={200}
            ></AgGridColumn>
            <AgGridColumn
              field="participant_info.total_points_given"
              headerName="Точки"
              checkboxSelection={false}
              rowDrag={false}
              maxWidth={74}
              cellClass="bold center-aligned bordered"
            ></AgGridColumn>
            <AgGridColumn
              field="participant_info.total_points_given"
              headerName="Точки"
              checkboxSelection={false}
              rowDrag={false}
              pinned="right"
              maxWidth={74}
              cellClass="bold center-aligned bordered"
            ></AgGridColumn>

            {hasModules
              ? rowData[0].modules.map((mod, modIndex) => {
                  return (
                    <AgGridColumn key={mod.module_id} headerName={mod.module_name}>
                      {mod.exercises.map((exc, excIndex) => {
                        return (
                          <AgGridColumn
                            valueGetter={exerciseValueGetter({ excIndex, modIndex, hasModules })}
                            cellClass={cellClassHandler({ excIndex, modIndex, hasModules })}
                            cellRenderer={exerciseCellRenderer({ excIndex, modIndex, hasModules })}
                            key={exc.exercise_id}
                            checkboxSelection={false}
                            autoHeight={true}
                            width={160}
                            headerName={`${exc.exercise_name} ${
                              exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                            } `}
                          ></AgGridColumn>
                        )
                      })}
                      <AgGridColumn
                        headerName={'ОБЩО Модул'}
                        valueGetter={getModulePoints(modIndex)}
                        checkboxSelection={false}
                        rowDrag={false}
                        width={130}
                        cellClass="bold center-aligned bordered"
                      ></AgGridColumn>
                    </AgGridColumn>
                  )
                })
              : rowData[0]?.exercises.map((exc, excIndex) => {
                  return (
                    <AgGridColumn
                      wrapText={true}
                      valueGetter={exerciseValueGetter({ excIndex, hasModules })}
                      cellClass={cellClassHandler({ excIndex, hasModules })}
                      cellRenderer={exerciseCellRenderer({ excIndex })}
                      key={exc.exercise_id}
                      autoHeight={true}
                      width={160}
                      checkboxSelection={false}
                      headerName={`${exc.exercise_name} ${
                        exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                      } `}
                    ></AgGridColumn>
                  )
                })}
          </AgGridReact>
        </div>
      </>
    )
  }
)(({ customCss }) => ({
  height: '800px',

  '.row-odd': {
    backgroundColor: COLORS.bgGray,
    opacity: 0.85
  },
  '.row-even': {
    backgroundColor: '#e6e6e6'
  },

  '.cell-styling': {
    whiteSpace: 'normal !important'
  },

  '.ag-header-cell-label': {
    justifyContent: 'left'
  },

  '.ag-cell': {
    lineHeight: '30px'
  },

  '.answer-gray': {
    backgroundColor: `rgba(${COLORS.borderGray}, 0.8)`,
    textAlign: 'center'
  },

  '.ag-row-hover': {
    backgroundColor: '#fff5a6'
  },

  '.center-aligned': {
    textAlign: 'center',
    justifyContent: 'center',
    '& .ag-cell-wrapper': {
      justifyContent: 'center'
    }
  },

  '.bold': {
    fontWeight: 900
  },

  '.bordered': {
    outline: `1px solid white`
  },

  '.denied': {
    color: COLORS.secRed,
    fontSize: 20
  },

  '.not-confirmed': {
    color: COLORS.secBlue,
    fontSize: 20
  },

  '.confirmed': {
    color: COLORS.secGreen,
    fontSize: 20
  },
  '.whitespace-normal': {
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  },
  '.answer-text-correct': {
    fontWeight: 700,
    color: COLORS.secGreen,
    textAlign: 'center'
  },
  '.answer-text-incorrect': {
    fontWeight: 700,
    color: COLORS.secRed,
    textAlign: 'center'
  },
  '.answer-text-null': {
    fontWeight: 700,
    color: COLORS.secYellow,
    textAlign: 'center'
  },
  '.user-answer-white': {
    fontWeight: 700
  },
  '.user-answer-null': {
    fontWeight: 700,
    backgroundColor: COLORS.secYellow,
    textAlign: 'center',
    '.answer-text-null': {
      color: '#000'
    }
  },
  '.answer-background-correct': {
    color: '#fff',
    fontWeight: 700,
    backgroundColor: COLORS.secGreen,
    textAlign: 'center'
  },
  '.cell-overflow-visible .ag-cell-value': {
    overflow: 'visible'
  },
  '.answer-background-incorrect': {
    color: '#fff',
    fontWeight: 700,
    backgroundColor: COLORS.secRed,
    textAlign: 'center'
  },
  ...customCss
}))

import styled from '@emotion/styled'
import { COLORS, QUERIES } from 'styling/variables'

export const KyosoLayout = styled('div')(({ customCss }) => ({
  '& #title': {
    fontSize: 22,
    marginBottom: 25,
    color: COLORS.secBlack,
    marginRight: 10,

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      fontSize: 18,
    },
  },
  ...customCss,
}))

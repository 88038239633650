import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getCompetitionInfo } from '../services/competition'

// Thunks
export const hub_competitionInfo = createAsyncThunk(
  'competition/hub_competitionInfo',
  async (data, thunkAPI) => {
    try {
      const response = await getCompetitionInfo(data.childId)

      return {
        ...response.info,
        participation_data: response.participation_data,
        participant_id: response.participant_id,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// Selectors
export const hub_selectCompetitionToken = (state) => {
  const { email, full_name, id } = state.hub.user
  const {
    childId,
    parentId,
    parentName,
    gradeName,
    subjectName,
    year,
    typeName,
    participationUserData,
    participantId,
  } = state.hub.competition

  return {
    email,
    fullName: participationUserData
      ? JSON.parse(participationUserData).full_name
      : full_name,
    participantId: participantId,
    userId: id,
    childId,
    parentId,
    parentName,
    gradeName,
    subjectName,
    year,
    typeName,
  }
}

export const hub_selectSubmitMessage = (state) => {
  const { submitMessage } = state.hub.competition

  return {
    submitMessage,
  }
}

export const hub_selectCompetitionInfo = (state) => state.hub.competition

// Configure Slice
const initialState = {
  childId: '',
  childUId: '',
  parentId: '',
  parentUId: '',
  parentName: '',
  gradeName: '',
  subjectName: '',
  year: '',
  typeName: '',
  submitMessage: '',
  participationUserData: '',
  participantId: '',
}

const competitionSlice = createSlice({
  name: 'competition',
  initialState,
  reducers: {},
  extraReducers: {
    [hub_competitionInfo.fulfilled]: (state, action) => {
      const {
        child_id,
        grade_name,
        name,
        parent_id,
        type_name,
        year,
        subject_name,
        submit_message,
        child_uid,
        parent_uid,
        participation_data,
        participant_id,
      } = action.payload

      state.childId = child_id
      state.parentId = parent_id
      state.parentName = name
      state.gradeName = grade_name
      state.subjectName = subject_name
      state.year = year
      state.typeName = type_name
      state.submitMessage = submit_message
      state.childUId = child_uid
      state.parentUId = parent_uid
      state.participationUserData = participation_data
      state.participantId = participant_id
    },
  },
})

// Actions and Reducer
// eslint-disable-next-line
export const {} = competitionSlice.actions
export default competitionSlice.reducer

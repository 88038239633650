import styled from '@emotion/styled'
import Parser from 'html-react-parser'
import { useEffect } from 'react'
import { COLORS } from 'styling/variables'

export const ConfirmationMessage = styled(
  ({
    className,
    message,
    iconClass,
    removeMessageCallback = () => {},
    removeAfterMs = 5000,
  }) => {
    useEffect(() => {
      if (removeMessageCallback) {
        setTimeout(() => {
          removeMessageCallback()
        }, removeAfterMs)
      }
      return () => {
        removeMessageCallback()
      }
    }, [])

    return (
      <div className={className}>
        {iconClass && <i className={iconClass}></i>}
        <h4>{Parser(message)}</h4>
      </div>
    )
  },
)(({ color, customCss }) => ({
  color,
  textAlign: 'center',
  padding: 20,
  width: '100%',

  '& h4': {
    fontSize: 21,
  },

  '& i': {
    fontSize: 51,
    paddingBottom: 15,
  },

  '& .sent-to-email': {
    color: COLORS.secRed,
  },

  ...customCss,
}))

import i18next from 'i18next'

export const removeMathFromString = (string) => {
  let div = document.createElement('div')
  div.innerHTML = string

  let elements = div.getElementsByTagName('math')

  while (elements[0]) elements[0].parentNode.removeChild(elements[0])

  return div.innerHTML
}

export const getModulePoints = (modIndex) => (params) => {
  const {
    node: { data }
  } = params
  const exercisesData = data.modules[modIndex]
  return exercisesData.module_given_total_points
}

// returns the class of the answer in the grid cell
export const getUserAnswerClass = (userAnswer, exerciseType, scoringType) => {
  let className = ''

  switch (exerciseType) {
    case '1':
      if (scoringType === '0' || scoringType === '2') {
        if (userAnswer.sub_answer_correct) {
          className = 'answer-text-correct'
        } else {
          className = 'answer-text-incorrect'
        }
      }
      break
    case '2':
      if (scoringType !== '1') {
        // 1 e kogato vsichki trqbva da sa verni - background-a se promenq text is default
        if (userAnswer.sub_answer_correct) {
          className = 'answer-text-correct'
        } else if (userAnswer.sub_answer === '') {
          className = 'answer-text-null'
        } else {
          className = 'answer-text-incorrect'
        }
      }
      break
    default:
      className = ''
      break
  }
  return className
}

export const exerciseCellRenderer = ({ excIndex, modIndex, hasModules }) => (params) => {
  const {
    node: { data }
  } = params
  const { user_answer: userAnswers, exercise_type, total_given_points, scoring_type } = hasModules
    ? data.modules[modIndex].exercises[excIndex]
    : data.exercises[excIndex]

  let exerciseCellTemplate = ''

  if (userAnswers && userAnswers.length) {
    switch (exercise_type) {
      case '0': // Ima edin vuzmojen otgovor
        if (userAnswers[0].sub_is_other) {
          exerciseCellTemplate = userAnswers
            .map((userAnswer) => {
              let text = i18next.t('hub:PersonalResults:MissingAnswer')
              let description = ''

              if (userAnswer.sub_answer_description !== '') {
                description = `${userAnswer.sub_answer_description} `
              }

              if (userAnswer.sub_answer) {
                text = `${userAnswer.sub_answer}`
              }
              return `${description}${text}`
            })
            .join('')
        } else {
          exerciseCellTemplate = removeMathFromString(userAnswers[0].sub_answer)
        }
        break
      case '1': // Ima mnogo zatvoreni otgovori ot mnojestvo
        exerciseCellTemplate = userAnswers
          .map(
            (userAnswer) => `<span class="${getUserAnswerClass(userAnswer, exercise_type, scoring_type)}">
                    ${removeMathFromString(userAnswer.sub_answer)}</span>`
          )
          .join('')
        break
      case '2': // Ima mnogo otvoreni otgovori ot mnojestvo
        exerciseCellTemplate = userAnswers
          .map((userAnswer) => {
            let text = ''
            let className = getUserAnswerClass(userAnswer, exercise_type, scoring_type)

            let description = ''

            if (userAnswer.sub_answer_description) {
              description = `${userAnswer.sub_answer_description} `
            }

            if (userAnswer.sub_answer === '') {
              text = `${description}${i18next.t('hub:PersonalResults:MissingAnswer')}`
            } else {
              text = description + removeMathFromString(userAnswer.sub_answer)
            }

            if (scoring_type === '1') {
              if (userAnswers.every((answer) => answer.sub_answer === '')) {
                className = ''
              }
            }

            if (userAnswers.length === 1) {
              // ako imame samo edin otgovor ocvetqvame background-a
              className = 'user-answer-white'
            }

            return `<span class="${className}">${text}</span>`
          })
          .join('<br>')
        break
      case '3': // manual scoring
        if (userAnswers.length > 1) {
          // there are multiple answers
          const allNull = userAnswers.every((answer) => answer.points === null)
          if (allNull) {
            // all answers are not scored
            exerciseCellTemplate = i18next.t('hub:PersonalResults:MissingAnswer')
          } else {
            exerciseCellTemplate = userAnswers
              ? userAnswers
                  .map((userAnswer) => {
                    if (typeof userAnswer.points === 'number') {
                      // answer has a score
                      return `${userAnswer.sub_answer_description} (${userAnswer.points}т.)`
                    } else {
                      // answer has no score
                      return `<span class="answer-text-null">${userAnswer.sub_answer_description} ${i18next.t(
                        'hub:PersonalResults:MissingAnswer'
                      )}</span>`
                    }
                  })
                  .join('<br/>')
              : ''

            exerciseCellTemplate += `<br/><b>Общо: ${total_given_points}</b>`
          }
        } else if (userAnswers.length === 1) {
          // there is only one answer
          if (userAnswers[0].points === null) {
            // all answers are not scored
            exerciseCellTemplate = i18next.t('hub:PersonalResults:MissingAnswer')
          } else {
            exerciseCellTemplate = `${userAnswers[0].points}т.`
          }
        }
        break
      default:
        break
    }
  } else {
    // there are no answers
    exerciseCellTemplate = `${i18next.t('hub:PersonalResults:MissingAnswer')}<br/>`
  }

  return exerciseCellTemplate
}

//exercise value getter
export const exerciseValueGetter = ({ excIndex, modIndex, hasModules }) => (params) => {
  const {
    node: { data }
  } = params
  const { user_answer: userAnswers, exercise_type, total_given_points } = hasModules
    ? data.modules[modIndex].exercises[excIndex]
    : data.exercises[excIndex]

  let exerciseCellTemplate = ''

  if (userAnswers && userAnswers.length) {
    switch (exercise_type) {
      case '0': // Ima edin vuzmojen otgovor
        if (userAnswers[0].sub_is_other) {
          exerciseCellTemplate = userAnswers
            .map((userAnswer) => {
              let text = i18next.t('hub:PersonalResults:MissingAnswer')
              let description = ''

              if (userAnswer.sub_answer_description !== '') {
                description = `${userAnswer.sub_answer_description} `
              }

              if (userAnswer.sub_answer) {
                text = `${userAnswer.sub_answer}`
              }
              return `${description}${text}`
            })
            .join(' ')
        } else {
          exerciseCellTemplate = removeMathFromString(userAnswers[0].sub_answer)
        }
        break
      case '1': // Ima mnogo zatvoreni otgovori ot mnojestvo
        exerciseCellTemplate = userAnswers.map((userAnswer) => removeMathFromString(userAnswer.sub_answer)).join(' ')
        break
      case '2': // Ima mnogo otvoreni otgovori ot mnojestvo
        exerciseCellTemplate = userAnswers
          .map((userAnswer) => {
            let text = ''
            let description = ''

            if (userAnswer.sub_answer_description) {
              description = `${userAnswer.sub_answer_description} `
            }
            if (userAnswer.sub_answer === '') {
              text = `${description}${i18next.t('hub:PersonalResults:MissingAnswer')}`
            } else {
              text = description + removeMathFromString(userAnswer.sub_answer)
            }

            return text
          })
          .join(' ')
        break
      case '3': // manual scoring
        if (userAnswers.length > 1) {
          // there are multiple answers
          const allNull = userAnswers.every((answer) => answer.points === null)
          if (allNull) {
            // all answers are not scored
            exerciseCellTemplate = i18next.t('hub:PersonalResults:MissingAnswer')
          } else {
            exerciseCellTemplate = userAnswers
              ? userAnswers
                  .map((userAnswer) => {
                    if (typeof userAnswer.points === 'number') {
                      // answer has a score
                      return `${userAnswer.sub_answer_description} (${userAnswer.points}т.)`
                    } else {
                      // answer has no score
                      return `${userAnswer.sub_answer_description} ${i18next.t('hub:PersonalResults:MissingAnswer')}`
                    }
                  })
                  .join(' ')
              : ''

            exerciseCellTemplate += `Общо: ${total_given_points}`
          }
        } else if (userAnswers.length === 1) {
          // there is only one answer
          if (userAnswers[0].points === null) {
            // all answers are not scored
            exerciseCellTemplate = i18next.t('hub:PersonalResults:MissingAnswer')
          } else {
            exerciseCellTemplate = `${userAnswers[0].points}т.`
          }
        }
        break
      default:
        break
    }
  } else {
    // there are no answers
    exerciseCellTemplate = `${i18next.t('hub:PersonalResults:MissingAnswer')}`
  }

  return exerciseCellTemplate
}

// here we change the cell class to alter the background color
export const cellClassHandler = ({ excIndex, modIndex, hasModules }) => (params) => {
  const {
    node: { data }
  } = params

  let className = null

  const {
    user_answer_is_correct: isCorrect,
    user_answer: userAnswers,
    exercise_type,
    scoring_type,
    total_given_points
  } = hasModules ? data.modules[modIndex].exercises[excIndex] : data.exercises[excIndex]

  switch (exercise_type) {
    case '0':
      if (userAnswers && userAnswers[0].sub_answer !== null && userAnswers.some((answer) => answer.sub_answer !== '')) {
        if (isCorrect) {
          className = 'answer-background-correct'
        } else {
          className = 'answer-background-incorrect'
        }
      } else {
        className = 'user-answer-null'
      }
      break
    case '1':
      switch (scoring_type) {
        case '0':
        case '2':
          if (userAnswers && userAnswers.some((answer) => answer.sub_answer !== '')) {
            if (userAnswers.every((answer) => answer.sub_answer_correct === true)) {
              className = 'answer-text-correct'
            } else {
              className = 'answer-text-incorrect'
            }
          } else {
            className = 'user-answer-null'
          }
          break
        case '1':
          if (userAnswers) {
            if (isCorrect) {
              className = 'answer-background-correct'
            } else {
              className = 'answer-background-incorrect'
            }
          } else {
            className = 'user-answer-null'
          }
          break
        default:
          className = ''
          break
      }
      break
    case '2':
      if (userAnswers && userAnswers.some((answer) => answer.sub_answer !== '')) {
        if (userAnswers.length === 1) {
          if (isCorrect) {
            className = 'answer-background-correct'
          } else {
            className = 'answer-background-incorrect'
          }
        } else {
          switch (scoring_type) {
            case '0':
            case '2':
              if (userAnswers) {
                if (userAnswers.every((answer) => answer.sub_answer_correct === true)) {
                  className = 'answer-text-correct'
                } else {
                  className = 'answer-text-incorrect'
                }
              } else {
                className = 'user-answer-null'
              }
              break
            case '1':
              if (userAnswers) {
                if (isCorrect) {
                  className = 'answer-background-correct'
                } else {
                  className = 'answer-background-incorrect'
                }
              } else {
                className = 'user-answer-null'
              }
              break
            default:
              className = ''
              break
          }
        }
      } else {
        className = 'user-answer-null'
      }
      break
    case '3':
      if (total_given_points !== null) {
        if (total_given_points === 0) {
          // zero points scored
          className = 'user-answer-white'
        } else if (total_given_points > 0) {
          // more than zero points scored
          className = 'user-answer-white'
        }
      } else {
        // no scoring given on this exercise
        className = 'user-answer-null'
      }
      break
    default:
      className = ''
      break
  }

  return className + ' bordered whitespace-normal center-aligned'
}

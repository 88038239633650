import styled from '@emotion/styled'
import { COLORS, QUERIES, THEMES } from 'styling/variables'

export const IconButton = styled('div')(({ customCss, theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  width: 90,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 28,
  borderRight: `1px solid ${COLORS.borderGray}`,
  color: COLORS.mainGray,
  '&:hover': {
    background: `${COLORS.bgGray} 0% 0% no-repeat padding-box`,
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    fontSize: 24,

    '&.menu': {
      backgroundColor: `${COLORS.secBlack}`,
      borderTop: `3px solid ${COLORS.secBlack}`,
      color: '#ffff',
    },
    '&.menu.active': {
      background: `${THEMES[+theme]} 0% 0% no-repeat padding-box`,
      color: '#ffff',
      borderTop: `3px solid ${THEMES[+theme]}`,
    },
  },

  ...customCss,
}))

import styled from '@emotion/styled'
import { QUERIES, THEMES } from 'styling/variables'

export const DialogBubble = styled('div')(({ customCss, theme }) => ({
  border: `3px solid ${THEMES[+theme]}`,
  borderRadius: '25px',
  padding: '20px',
  fontWeight: '900',
  color: `${THEMES[+theme]}`,
  textAlign: 'left',
  position: 'relative',
  height: 'fit-content',
  width: '50%',

  '&.arrow-left:before': {
    content: '""',
    width: '0px',
    height: '0px',
    position: 'absolute',
    borderLeft: '10px solid transparent',
    borderRight: `10px solid ${THEMES[+theme]}`,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    left: '-23px',
    bottom: 'calc(50% - 20px)',
    transform: 'translateY(-50%)',
  },
  '&.arrow-left:after': {
    content: '""',
    width: '0px',
    height: '0px',
    position: 'absolute',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid #fff',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    left: '-20px',
    bottom: 'calc(50% - 20px)',
    transform: 'translateY(-50%)',
  },
  '&.arrow-right': {
    justifySelf: 'right',
  },

  '&.arrow-right:before': {
    content: '""',
    width: '0px',
    height: '0px',
    position: 'absolute',
    borderLeft: `10px solid ${THEMES[+theme]}`,
    borderRight: '10px solid transparent',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    right: '-20px',
    bottom: 'calc(50% - 20px)',
    transform: 'translateY(-50%)',
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    width: '100%',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: 10,
    p: {
      fontSize: 16,
    },

    '&.arrow-left:before': {},
    '&.arrow-left:after': {},

    '&.arrow-right:before': {},
  },
  ...customCss,
}))

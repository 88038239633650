import styled from '@emotion/styled'
import React from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { getModulePoints, exerciseCellRenderer, cellClassHandler } from 'utils/helpers/grid'
import { COLORS } from 'styling/variables'

export const AgGridCompPublic = styled(
  ({
    hasModules,
    rowData,
    gridOptions,
    customColDef,
    columnDefs,
    className,
    setGridApiCallback = () => {},
    setGridColumnApiCallback = () => {},
    currentUser,
    competitionType
  }) => {
    const defaultColDef = {
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: 'agTextColumnFilter',
      ...customColDef
    }

    const defaultColGroupDef = {}

    const columnTypes = {
      editableColumn: { editable: true }
    }

    const onGridReady = (params) => {
      setGridApiCallback(params.api)
      setGridColumnApiCallback(params.columnApi)
    }

    const placeColor = () => (params) => {
      const {
        node: { data }
      } = params

      let className = ''

      let rank = data.rank

      if (Number(competitionType) === 1) {
        if (Number(rank) === 1) {
          className = 'gold-place'
        } else if (Number(rank) === 2) {
          className = 'silver-place'
        } else if (Number(rank) === 3) {
          className = 'bronze-place'
        } else {
          className = ''
        }
      }

      return className + ' center-aligned bordered'
    }

    return (
      <div className={`${className} ag-theme-alpine`}>
        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          defaultColDef={{
            ...defaultColDef,
            resizable: true
          }}
          columnDefs={columnDefs}
          defaultColGroupDef={defaultColGroupDef}
          columnTypes={columnTypes}
          rowData={rowData}
          gridOptions={{
            getRowClass: (params) => {
              if (params.node.data.participant_info.user_id === currentUser) {
                if (params.node.rowIndex % 2 === 0) {
                  return 'row-odd current'
                } else return 'row-even current'
              } else {
                if (params.node.rowIndex % 2 === 0) {
                  return 'row-odd'
                } else return 'row-even'
              }
            },
            enableCellTextSelection: true,
            ...gridOptions
          }}
          suppressRowClickSelection={true}
          rowSelection={'multiple'}
          onGridReady={onGridReady}
        >
          <AgGridColumn
            field="rank"
            pinned="left"
            headerName="No."
            editable={true}
            maxWidth={60}
            cellClass={placeColor()}
            checkboxSelection={false}
            rowDrag={false}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.participant_uid"
            headerName="Участник No."
            checkboxSelection={false}
            rowDrag={false}
            maxWidth={120}
            pinned="left"
            cellClass={placeColor()}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.name"
            headerName="Име на участник"
            checkboxSelection={false}
            rowDrag={false}
            editable={true}
            cellClass="whitespace-normal bordered"
            autoHeight={true}
            width={300}
            maxWidth={400}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.school"
            headerName="Учебно заведение"
            checkboxSelection={false}
            rowDrag={false}
            autoHeight={true}
            cellClass="bordered whitespace-normal"
            width={250}
            maxWidth={300}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.city"
            headerName="Населено място"
            checkboxSelection={false}
            rowDrag={false}
            autoHeight={true}
            cellClass="bordered whitespace-normal"
            width={150}
            maxWidth={200}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.total_points_given"
            headerName="Точки"
            checkboxSelection={false}
            rowDrag={false}
            maxWidth={74}
            cellClass="bold center-aligned bordered"
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.total_points_given"
            headerName="Точки"
            checkboxSelection={false}
            rowDrag={false}
            pinned="right"
            maxWidth={74}
            cellClass="bold center-aligned bordered"
          ></AgGridColumn>

          {hasModules
            ? rowData[0].modules.map((mod, modIndex) => {
                return (
                  <AgGridColumn key={mod.module_id} headerName={mod.module_name}>
                    {mod.exercises.map((exc, excIndex) => {
                      return (
                        <AgGridColumn
                          cellClass={cellClassHandler({ excIndex, modIndex, hasModules })}
                          cellRenderer={exerciseCellRenderer({ excIndex, modIndex, hasModules })}
                          key={exc.exercise_id}
                          checkboxSelection={false}
                          width={160}
                          autoHeight={true}
                          headerName={`${exc.exercise_name} ${
                            exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                          } `}
                        ></AgGridColumn>
                      )
                    })}
                    <AgGridColumn
                      headerName={'ОБЩО Модул'}
                      valueGetter={getModulePoints(modIndex)}
                      checkboxSelection={false}
                      rowDrag={false}
                      width={130}
                      cellClass="bold center-aligned bordered"
                    ></AgGridColumn>
                  </AgGridColumn>
                )
              })
            : rowData[0].exercises.map((exc, excIndex) => {
                return (
                  <AgGridColumn
                    wrapText={true}
                    cellClass={cellClassHandler({ excIndex, hasModules })}
                    cellRenderer={exerciseCellRenderer({ excIndex, hasModules })}
                    key={exc.exercise_id}
                    width={160}
                    autoHeight={true}
                    checkboxSelection={false}
                    headerName={`${exc.exercise_name} ${
                      exc.total_max_points !== null ? `(${exc.total_max_points}т.)` : ' '
                    } `}
                  ></AgGridColumn>
                )
              })}
        </AgGridReact>
      </div>
    )
  }
)(({ customCss }) => ({
  height: '750px',
  marginBottom: '20px',

  '.row-odd': {
    backgroundColor: COLORS.bgGray,
    opacity: 0.85
  },
  '.row-even': {
    backgroundColor: '#e6e6e6'
  },

  '.current': {
    backgroundColor: `rgba(0, 134, 206, 0.25)`
  },

  '.cell-styling': {
    whiteSpace: 'normal !important'
  },

  '.ag-row-hover': {
    backgroundColor: '#fff5a6'
  },

  '.ag-header-cell-label': {
    justifyContent: 'left'
  },

  '.answer-text-correct': {
    fontWeight: 700,
    color: COLORS.secGreen,
    textAlign: 'center'
  },
  '.answer-text-incorrect': {
    fontWeight: 700,
    color: COLORS.secRed,
    textAlign: 'center'
  },
  '.answer-text-null': {
    fontWeight: 700,
    color: COLORS.secYellow,
    textAlign: 'center'
  },
  '.user-answer-white': {
    fontWeight: 700
  },
  '.user-answer-null': {
    fontWeight: 700,
    backgroundColor: COLORS.secYellow,
    textAlign: 'center',
    '.answer-text-null': {
      color: '#000'
    }
  },
  '.answer-background-correct': {
    color: '#fff',
    fontWeight: 700,
    backgroundColor: COLORS.secGreen,
    textAlign: 'center'
  },
  '.answer-background-incorrect': {
    color: '#fff',
    fontWeight: 700,
    backgroundColor: COLORS.secRed,
    textAlign: 'center'
  },

  '.ag-cell': {
    lineHeight: '30px'
  },

  '.answer-gray': {
    backgroundColor: `rgba(${COLORS.borderGray}, 0.8)`,
    textAlign: 'center'
  },

  '.center-aligned': {
    textAlign: 'center',
    justifyContent: 'center',
    '& .ag-cell-wrapper': {
      justifyContent: 'center'
    }
  },

  '.bold': {
    fontWeight: 700
  },

  '.bordered': {
    outline: `1px solid white`
  },

  '.denied': {
    color: COLORS.secRed,
    fontSize: 20
  },

  '.not-confirmed': {
    color: COLORS.secBlue,
    fontSize: 20
  },

  '.confirmed': {
    color: COLORS.secGreen,
    fontSize: 20
  },

  '.whitespace-normal': {
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  },
  '.gold-place': {
    backgroundColor: '#FFD700'
  },
  '.silver-place': {
    backgroundColor: '#C0C0C0'
  },
  '.bronze-place': {
    backgroundColor: '#CD7F32'
  },
  ...customCss
}))
